import ACTIONS from '../constants/actionsTypes';

const initialState = {
    list        : [],
    filtersList : {},
    error       : null,
    isLoading   : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_FILTER_CATALOG_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_FILTER_CATALOG_SUCCESS:
            return {
                ...state,
                list        : data.filterCatalog,
                filtersList : data.filters,
                isLoading   : false
            };
        case ACTIONS.GET_FILTER_CATALOG_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };
        default:
            return state;
    }
};
