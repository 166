import React from 'react';

function Ellipse() {
    return (
        <svg
            width='11'
            height='11'
            viewBox='0 0 11 11'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <circle
                cx='5.5'
                cy='5.5'
                r='4.5'
                fill='#62DF4D'
                stroke='white'
                strokeWidth='2'
            />
        </svg>

    );
}

export default React.memo(Ellipse);
