import React,
{
    useCallback,
    useEffect,
    useMemo,
    useState
}                       from 'react';
import Masonry          from 'react-masonry-css';
import PropTypes        from 'prop-types';
import classNames       from 'classnames';
import { Link }         from 'react-router-dom';
import PercentIcon      from '../../assets/icon/PercentIcon';
import ExpertPharmacist from '../ExpertPharmacist';
import style            from './NavigationPopup.module.scss';

function Tablet({ list, onClickLink }) {
    const breakpointColumnsObj = {
        default : 3,
        1100    : 3,
        700     : 2,
        500     : 1
    };

    const [ activeTab, setActiveTab ] = useState(null);

    useEffect(() => {
        if (!list.length) return;

        setActiveTab(list[0]?.id);
    }, [ list ]);

    const showTab = useCallback(({ target }) => {
        const id = +target.closest('li').getAttribute('data-id');

        setActiveTab(id);
    }, [ activeTab ]);

    const listItem = useMemo(() => {
        if (!list.length) return;

        return list.filter(item => {
            if (item?.id === activeTab) {
                return item.children;
            }
        });
    }, [ list, activeTab ]);

    return (
        <div className={style.wrapper}>
            <nav className={style.nav}>
                <ul>
                    <li>
                        <Link to={'/'}>
                            Пропозиції

                            <PercentIcon />
                        </Link>
                    </li>
                    {
                        list?.map(item => (
                            <li
                                key={item?.id}
                                data-id={item?.id}
                                onClick={showTab}>
                                <button className={classNames({ [style.active]: activeTab === item?.id })}>
                                    { item?.nameUa }
                                </button>
                            </li>
                        ))
                    }
                </ul>

                <ExpertPharmacist />
            </nav>

            <div className={style.dropdown}>
                {
                    (listItem && listItem[0]?.children) &&
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className={style['my-masonry-grid']}
                            columnClassName={style['my-masonry-grid_column']}>
                            {
                                listItem && listItem[0]?.children?.map(item => (
                                    <ul key={item?.id}>
                                        <li>
                                            <h4>
                                                { item.nameUa }
                                            </h4>
                                        </li>

                                        {
                                            item?.children?.map(child => (
                                                <li key={child?.id}>
                                                    <span
                                                        onClick={onClickLink.bind(null, child?.id)}
                                                        className={style.link}
                                                    >
                                                        {child?.nameUa}
                                                    </span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                ))
                            }
                        </Masonry>
                }
            </div>
        </div>
    );
}

Tablet.propTypes = {
    list        : PropTypes.array,
    onClickLink : PropTypes.func.isRequired
};

Tablet.defaultProps = {
    list : []
};

export default React.memo(Tablet);
