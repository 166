import ACTIONS from '../constants/actionsTypes';

const initialState = {
    list       : [],
    sorting    : 'price.desc',
    priceRange : {
        from : 0,
        to   : 10000
    }
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_CATALOG_ACTIVE_CHECKBOX:
            // eslint-disable-next-line no-case-declarations
            let filteredItems = [ ...state.list ];
            // eslint-disable-next-line no-case-declarations
            const index = filteredItems.findIndex(item => item.id === data.id && item.checked !== data.checked);

            if (index !== -1) {
                filteredItems.splice(index, 1);
            } else {
                filteredItems = [ ...filteredItems, data ];
            }

            return {
                ...state,
                list : filteredItems
            };

            case ACTIONS.REMOVE_CATALOG_ACTIVE_CHECKBOX:
            // eslint-disable-next-line no-case-declarations


            return {
                ...state,
                list : []
            };
        case ACTIONS.MOUNT_CATALOG_ACTIVE_CHECKBOX:
            return {
                ...state,
                list : data
            };
        case ACTIONS.CHANGE_SORTING:
            return {
                ...state,
                sorting : data
            };
        case ACTIONS.REMOVE_CHANGE_PRICE_RANGE:
            return {
                ...state,
                priceRange : {
                    from : 0,
                    to   : 10000
                }
            };

        case ACTIONS.CHANGE_PRICE_RANGE:
            return {
                ...state,
                priceRange : { ...state.priceRange, ...data }
            };
        default:
            return state;
    }
};
