import React from 'react';

function SearchIcon() {
    return (
        <svg
            style={{ minWidth: '1.125rem' }} width='18' height='17'
            viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8.17019 0.188354C4.05841 0.188354 0.708984 3.53663 0.708984 7.64704C0.708984 11.7574 4.05841 15.1122 8.17019 15.1122C9.92646 15.1122 11.5417 14.4969 12.8183 13.4754L15.9262 16.5806C16.083 16.7309 16.2925 16.8138 16.5097 16.8116C16.727 16.8094 16.9347 16.7222 17.0884 16.5687C17.2422 16.4153 17.3297 16.2078 17.3322 15.9906C17.3347 15.7734 17.2521 15.5639 17.1019 15.4069L13.9941 12.3001C15.0168 11.0219 15.633 9.40473 15.633 7.64704C15.633 3.53663 12.282 0.188354 8.17019 0.188354ZM8.17019 1.84622C11.3857 1.84622 13.973 4.43262 13.973 7.64704C13.973 10.8615 11.3857 13.4544 8.17019 13.4544C4.95468 13.4544 2.36739 10.8615 2.36739 7.64704C2.36739 4.43262 4.95468 1.84622 8.17019 1.84622Z' fill='currentColor' />
        </svg>
    );
}

export default React.memo(SearchIcon);
