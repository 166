import {
    SUCCESS,
    BAD_SERVER_RESPONSE_RANGE
}                       from '../constants/serverCodes';
import { processError } from '../utils/errorUtils';

const CONTENT_TYPES = {
    'JSON'                  : 'application/json; charset=utf-8',
    'accept'                : 'application/json',
    'X_WWW_FORM_URLENCODED' : 'application/x-www-form-urlencoded; charset=utf-8'
};

export default class ApiClient {
    constructor(apiUrl) {
        if (!apiUrl) throw new Error('[apiUrl] required');

        this.apiUrl = apiUrl;
        this.token = '';

        const methods = [ 'get', 'post', 'put', 'patch', 'delete' ];

        methods.forEach(method => {
            this[method] = ({ requestURL, queryParams = {}, payload = {}, useCache = false }) => {
                return this._request({ requestURL, method, queryParams, payload, useCache });
            };
        });
    }

    async _request({ requestURL, method, payload = {}, queryParams = {} }) {
        const url     = this._getUrl(requestURL, queryParams);
        const options = this._getRequestOptions(method, payload, queryParams);

        const response = await fetch(url, options);

        return this.processResponse(response, requestURL);
    }

    async processResponse(response, requestURL) {
        if (response.status >= BAD_SERVER_RESPONSE_RANGE) {
            return processError(response, requestURL);
        }

        if (response.status === SUCCESS) {
            const resp = await response.json();

            return resp;
        }
    }

    _getUrl(url, params) {
        const query = Object.keys(params).length ? `?${this._encodeQueryString(params)}` : '';

        return `${this.apiUrl}/${url}${query}`;
    }

    _getRequestOptions(method, payload, params) {
        const contentType = this._getContentTypeByMethod(method);
        const body = this._getBodyByContentType(method, payload);
        // const session = localStorage.getItem('sessionId');

        return {
            resolveWithFullResponse : true,
            headers                 : {
                'Content-Type' : contentType,
                ...(method !== 'get' && this.token && { 'Authorization': `Bearer ${this.token}` })
                // ...(session && { 'Cookie': `PHPSESSID=${session}` })
            },
            method : method.toUpperCase(),
            ...(method !== 'get' && { body }),
            params
        };
    }

    _getContentTypeByMethod(method) {
        return method === 'get'
            ? CONTENT_TYPES.X_WWW_FORM_URLENCODED
            : CONTENT_TYPES.JSON;
    }

    _getBodyByContentType(method, payload) {
        return method !== 'get'
            ? JSON.stringify(payload)
            : {};
    }

    _encodeQueryString(params) {
        return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    }

    setToken(token, id) {
        this.token = token;

        if (token)  {
            const expiryDate = new Date();

            expiryDate.setTime(expiryDate.getTime() + (2 * 24 * 60 * 60 * 1000));
            document.cookie = `token=${token}; expires=${expiryDate.toUTCString()}; path=/`;
            document.cookie = `user_id=${id}; expires=${expiryDate.toUTCString()}; path=/`;
        }
    }
}
