import React from 'react';

function HeartIcon() {
    return (
        <svg
            style={{ minWidth: '1.5rem' }} width='24' height='20'
            viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M22.0718 1.95738C19.4616 -0.652106 15.2154 -0.652106 12.6059 1.95738L11.9998 2.56313L11.394 1.95738C8.78455 -0.652459 4.53795 -0.652459 1.92847 1.95738C-0.628032 4.51388 -0.644633 8.56621 1.88997 11.3837C4.2017 13.9526 11.0196 19.5025 11.3089 19.7374C11.5053 19.8971 11.7416 19.9748 11.9765 19.9748C11.9842 19.9748 11.992 19.9748 11.9994 19.9744C12.2424 19.9857 12.4872 19.9023 12.6899 19.7374C12.9792 19.5025 19.7979 13.9526 22.1103 11.3834C24.6445 8.56621 24.6279 4.51388 22.0718 1.95738ZM20.535 9.96596C18.7326 11.9683 13.7782 16.098 11.9994 17.5634C10.2207 16.0983 5.26732 11.969 3.46527 9.96632C1.69712 8.00108 1.68052 5.20228 3.42677 3.45603C4.31861 2.56454 5.48984 2.11844 6.66107 2.11844C7.8323 2.11844 9.00354 2.56419 9.89538 3.45603L11.2277 4.78832C11.3863 4.94691 11.5862 5.04157 11.796 5.07477C12.1365 5.14789 12.5059 5.05287 12.7708 4.78867L14.1038 3.45603C15.8879 1.67269 18.7898 1.67305 20.5728 3.45603C22.319 5.20228 22.3024 8.00108 20.535 9.96596Z' fill='#1E1E2D' />
        </svg>
    );
}

export default React.memo(HeartIcon);
