import ACTIONS from '../constants/actionsTypes';

const initialState = {
    showcases : null,
    error     : null,
    isLoading : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_SHOWCASES_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_SHOWCASES_SUCCESS:
            return {
                ...state,
                showcases : data,
                isLoading : false
            };
        case ACTIONS.GET_SHOWCASES_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };
        default:
            return state;
    }
};
