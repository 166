import React from 'react';
import loader from '../images/spiner.svg';

function SpinnerIcon() {
    return (
        <>
            <img   src={loader} alt='spinner' />
        </>
    );
}

export default React.memo(SpinnerIcon);
