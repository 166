import Base from './Base.js';

class PersonalArea extends Base {
    list(id) {
        return this.apiClient.get({
            requestURL : `api/account/get/orders/all/user/${id}`
        });
    }

    info(id) {
        return this.apiClient.get({
            requestURL : `api/account/info/${id}`
        });
    }

    last(id) {
        return this.apiClient.get({
            requestURL : `api/account/get/orders/last/user/${id}`
        });
    }

    saveInfo(id) {
        return this.apiClient.post({
            requestURL : `api/account/info/${id}`
        });
    }

    changePassword(params) {
        return this.apiClient.post({
            requestURL : `api/account/${params.id}/password/change`,
            payload    : params
        });
    }

    updateInfo(id, params) {
        return this.apiClient.post({
            requestURL : `api/account/info/${id}`,
            payload    : params
        });
    }

    recommendationList(id = 1) {
        return this.apiClient.get({
            requestURL : `api/account/recommended/${id}`
        });
    }
}

export default PersonalArea;
