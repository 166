import React from 'react';

function DocumentIcon() {
    return (
        <svg
            width='20' height='23' viewBox='0 0 20 23'
            fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: '1.25rem' }}>
            <path d='M16.292 1.9379C15.8559 1.50093 15.2761 1.26026 14.6591 1.26026H13.6169V1.05022C13.6169 0.471127 13.1458 0 12.5667 0H5.00515C4.42606 0 3.95494 0.471127 3.95494 1.05022V1.26026H2.90905C1.63782 1.26026 0.602435 2.29561 0.601007 3.56817L0.583742 19.1954C0.58307 19.813 0.822813 20.3938 1.25882 20.8308C1.69487 21.2678 2.2748 21.5084 2.89178 21.5084H14.6418C15.913 21.5084 16.9484 20.4731 16.9498 19.2005L16.9671 3.5733C16.9678 2.95564 16.728 2.37483 16.292 1.9379V1.9379ZM5.2152 1.26026H12.3567V2.52052H5.2152V1.26026ZM15.6909 19.1991C15.6902 19.7776 15.2196 20.2482 14.6418 20.2482H2.89178C2.61133 20.2482 2.34773 20.1388 2.14953 19.9402C1.95133 19.7415 1.84236 19.4775 1.84266 19.1968L1.85992 3.5696C1.86055 2.9911 2.33118 2.52052 2.90905 2.52052H3.95494V2.73056C3.95494 3.30965 4.42606 3.78078 5.00515 3.78078H12.5667C13.1458 3.78078 13.6169 3.30965 13.6169 2.73056V2.52052H14.6591C14.9395 2.52052 15.2031 2.62991 15.4013 2.82853C15.5995 3.02714 15.7085 3.29117 15.7082 3.57187L15.6909 19.1991Z' fill='black' fillOpacity='0.7' />
            <path d='M8.9895 8.40162H13.4664C13.8144 8.40162 14.0965 8.11949 14.0965 7.77149C14.0965 7.42349 13.8144 7.14136 13.4664 7.14136H8.9895C8.64151 7.14136 8.35938 7.42349 8.35938 7.77149C8.35938 8.11949 8.64151 8.40162 8.9895 8.40162V8.40162Z' fill='black' fillOpacity='0.7' />
            <path d='M8.9895 12.6025H13.4664C13.8144 12.6025 14.0965 12.3204 14.0965 11.9724C14.0965 11.6244 13.8144 11.3423 13.4664 11.3423H8.9895C8.64151 11.3423 8.35938 11.6244 8.35938 11.9724C8.35938 12.3204 8.64151 12.6025 8.9895 12.6025V12.6025Z' fill='black' fillOpacity='0.7' />
            <path d='M13.4844 15.5432H8.9895C8.64151 15.5432 8.35938 15.8253 8.35938 16.1733C8.35938 16.5213 8.64151 16.8035 8.9895 16.8035H13.4844C13.8324 16.8035 14.1146 16.5213 14.1146 16.1733C14.1146 15.8253 13.8325 15.5432 13.4844 15.5432Z' fill='black' fillOpacity='0.7' />
            <path d='M6.30752 6.05578L4.85435 7.50894L4.51173 7.16627C4.26564 6.92019 3.86669 6.92019 3.6206 7.16627C3.37451 7.41232 3.37451 7.81132 3.6206 8.0574L4.40881 8.84565C4.52698 8.96382 4.68724 9.03024 4.85439 9.03024C5.0215 9.03024 5.18177 8.96387 5.29994 8.84565L7.19869 6.94695C7.44477 6.7009 7.44477 6.3019 7.19869 6.05582C6.9526 5.80969 6.55364 5.80969 6.30752 6.05578V6.05578Z' fill='black' fillOpacity='0.7' />
            <path d='M6.30752 10.5775L4.85435 12.0306L4.51173 11.688C4.26564 11.4419 3.86669 11.4419 3.6206 11.688C3.37451 11.9341 3.37451 12.333 3.6206 12.5791L4.40881 13.3674C4.52698 13.4856 4.68724 13.552 4.85439 13.552C5.0215 13.552 5.18181 13.4856 5.29994 13.3674L7.19869 11.4686C7.44477 11.2226 7.44477 10.8236 7.19869 10.5775C6.9526 10.3314 6.5536 10.3314 6.30752 10.5775V10.5775Z' fill='black' fillOpacity='0.7' />
            <path d='M6.30752 14.7783L4.85435 16.2314L4.51173 15.8888C4.26564 15.6427 3.86669 15.6427 3.6206 15.8888C3.37451 16.1349 3.37451 16.5338 3.6206 16.7799L4.40881 17.5682C4.52698 17.6864 4.68724 17.7528 4.85439 17.7528C5.0215 17.7528 5.18181 17.6864 5.29994 17.5682L7.19869 15.6694C7.44477 15.4234 7.44477 15.0244 7.19869 14.7783C6.9526 14.5322 6.5536 14.5322 6.30752 14.7783V14.7783Z' fill='black' fillOpacity='0.7' />
            <circle
                cx='15.7426' cy='19.3262' r='3.6737'
                fill='#009EF7' />
            <path
                d='M15.7417 18V20.6524' stroke='#F5F5F5' strokeWidth='0.5'
                strokeLinecap='round' />
            <path
                d='M17.0681 19.3263L14.4157 19.3263' stroke='#F5F5F5' strokeWidth='0.5'
                strokeLinecap='round' />
        </svg>
    );
}

export default React.memo(DocumentIcon);
