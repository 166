import React from 'react';

function ArrowNavRight() {
    return (
        <svg
            style={{ minWidth: '0.563rem' }}
            width='9'
            height='15' viewBox='0 0 9 15'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1 1L7.5 7.5L1 14'
                stroke='#66697E'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round' />
        </svg>
    );
}

export default React.memo(ArrowNavRight);
