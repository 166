function formatTimeRange(open, close) {
    return `${open} - ${close}`;
}

function formatDayLabel(day) {
    const dayLabels = [ 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд' ];


return dayLabels[day - 1];
}

export function getWorkingHoursSchedule(schedule) {
    const result = [];

    let currentDay = 1;

    let currentRange = { open: '', close: '' };

    let currentRangeDays = [];

    while (currentDay <= 7) {
        const currentDaySchedule = schedule[currentDay];

        if (!currentDaySchedule) {
            if (currentRangeDays.length > 0) {
                const daysRange =
                    currentRangeDays.length === 1
                        ? formatDayLabel(currentRangeDays[0])
                        : `${formatDayLabel(currentRangeDays[0])}-${formatDayLabel(
                              currentRangeDays[currentRangeDays.length - 1]
                          )}`;

                result.push(`${daysRange}: ${formatTimeRange(currentRange.open, currentRange.close)}`);
            }
            currentRange = { open: '', close: '' };
            currentRangeDays = [];
            currentDay++;
            continue;
        }

        if (
            currentDaySchedule.open === currentRange.open &&
            currentDaySchedule.close === currentRange.close
        ) {
            currentRangeDays.push(currentDay);
        } else {
            if (currentRangeDays.length > 0) {
                const daysRange =
                    currentRangeDays.length === 1
                        ? formatDayLabel(currentRangeDays[0])
                        : `${formatDayLabel(currentRangeDays[0])}-${formatDayLabel(
                              currentRangeDays[currentRangeDays.length - 1]
                          )}`;

                result.push(`${daysRange}: ${formatTimeRange(currentRange.open, currentRange.close)}`);
            }
            currentRange = currentDaySchedule;
            currentRangeDays = [ currentDay ];
        }

        currentDay++;
    }

    if (currentRangeDays.length > 0) {
        const daysRange =
            currentRangeDays.length === 1
                ? formatDayLabel(currentRangeDays[0])
                : `${formatDayLabel(currentRangeDays[0])}-${formatDayLabel(
                      currentRangeDays[currentRangeDays.length - 1]
                  )}`;

        result.push(`${daysRange}: ${formatTimeRange(currentRange.open, currentRange.close)}`);
    }

    return result;
}

export function dumpProductsAvailabilityList(products) {
    const dumpedProducts = products?.map(product => ({
        ...product,
        time : getWorkingHoursSchedule(product?.time)
    }));

    return dumpedProducts;
}
