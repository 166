import React from 'react';

function OnlineIcon() {
    return (
        <svg
            width='22' height='32' viewBox='0 0 22 32'
            fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: '1.375rem' }}>
            <path d='M13.3473 11.2643C14.8142 10.3985 15.7968 8.82709 15.7968 7.03593C15.7968 4.31294 13.5261 2.09766 10.735 2.09766C7.94397 2.09766 5.6733 4.31294 5.6733 7.03593C5.6733 8.82709 6.6559 10.3985 8.12276 11.2643C5.70271 12.2616 4 14.6007 4 17.3206V22.9935H17.4701V17.3206C17.4701 14.6007 15.7674 12.2616 13.3473 11.2643ZM13.9143 18.7082C13.9143 19.0458 13.6328 19.3204 13.2868 19.3204C12.9408 19.3204 12.6593 19.0458 12.6593 18.7082C12.6593 18.3707 12.9408 18.096 13.2868 18.096C13.6328 18.096 13.9143 18.3707 13.9143 18.7082ZM14.4789 6.36237C12.9322 6.0848 11.7099 4.89227 11.4254 3.3834C12.9721 3.66097 14.1944 4.85346 14.4789 6.36237ZM10.0446 3.3834C9.76012 4.89231 8.53778 6.0848 6.99119 6.36237C7.27569 4.85346 8.498 3.66097 10.0446 3.3834ZM6.97287 7.60448C8.59429 7.39369 9.97539 6.43126 10.735 5.08907C11.4947 6.43126 12.8758 7.39369 14.4972 7.60448C14.2159 9.38345 12.636 10.7498 10.735 10.7498C8.83408 10.7498 7.25415 9.38345 6.97287 7.60448ZM16.2151 21.7691H5.25497V17.3206C5.25497 15.4398 6.25573 13.7828 7.76492 12.8296V14.9363C7.03457 15.1891 6.50995 15.8695 6.50995 16.6676V17.892H7.76492V16.6676C7.76492 16.33 8.04641 16.0554 8.39241 16.0554C8.73841 16.0554 9.0199 16.33 9.0199 16.6676V17.892H10.2749V16.6676C10.2749 15.8695 9.75025 15.1891 9.0199 14.9363V12.2427C9.5597 12.0687 10.1362 11.9742 10.735 11.9742C11.4119 11.9742 12.0603 12.0949 12.6593 12.3147V16.977C11.929 17.2297 11.4043 17.9101 11.4043 18.7082C11.4043 19.7209 12.2488 20.5448 13.2868 20.5448C14.3248 20.5448 15.1693 19.7209 15.1693 18.7082C15.1693 17.9101 14.6447 17.2297 13.9143 16.977V12.9683C15.3061 13.9389 16.2151 15.5281 16.2151 17.3206V21.7691Z' fill='#4D4D4D' />
            <circle
                cx='15.5' cy='4' r='3'
                fill='#50CD89' stroke='white' />
            <path d='M2.292 30.572C1.688 30.572 1.188 30.378 0.792 29.99C0.4 29.598 0.204 29.102 0.204 28.502C0.204 27.902 0.4 27.408 0.792 27.02C1.188 26.628 1.688 26.432 2.292 26.432C2.9 26.432 3.4 26.626 3.792 27.014C4.188 27.402 4.386 27.898 4.386 28.502C4.386 29.106 4.188 29.602 3.792 29.99C3.4 30.378 2.9 30.572 2.292 30.572ZM1.41 29.444C1.634 29.692 1.928 29.816 2.292 29.816C2.656 29.816 2.95 29.692 3.174 29.444C3.398 29.196 3.51 28.882 3.51 28.502C3.51 28.122 3.398 27.808 3.174 27.56C2.95 27.312 2.656 27.188 2.292 27.188C1.928 27.188 1.634 27.312 1.41 27.56C1.19 27.808 1.08 28.122 1.08 28.502C1.08 28.882 1.19 29.196 1.41 29.444ZM8.56589 30.5H7.74389L5.83589 27.89V30.5H4.98389V26.498H5.85989L7.71389 29.012V26.498H8.56589V30.5ZM11.899 30.5H9.35498V26.498H10.207V29.75H11.899V30.5ZM13.3476 30.5H12.4956V26.498H13.3476V30.5ZM17.7241 30.5H16.9021L14.9941 27.89V30.5H14.1421V26.498H15.0181L16.8721 29.012V26.498H17.7241V30.5ZM21.3452 30.5H18.5132V26.498H21.3452V27.248H19.3652V28.094H21.3032V28.844H19.3652V29.75H21.3452V30.5Z' fill='#4D4D4D' />
        </svg>
    );
}

export default React.memo(OnlineIcon);
