import api     from '../../apiSingleton';
import ACTIONS from '../constants/actionsTypes';

export function getCart() {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_CART_REQUEST
            });

            const session = localStorage.getItem('sessionId');
            const listTest = localStorage.getItem('cart');

            // const storedJson = await api.cart.list(session);
            // console.log(storedJson);
            await api.cart.list(session);

            dispatch({
                type : ACTIONS.GET_CART_SUCCESS,
                data : listTest ? { items: JSON.parse(listTest) } : {}
            });
        } catch (err) {
            console.log('[ERROR] getCart', err);
            dispatch({
                type : ACTIONS.GET_CART_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
