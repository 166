import React from 'react';
import LoaderIcon from '../../assets/icon/LoaderIcon';
import style from './Loader.module.scss';

function Loader() {
    return (
        <div className={style.loader}>
            <LoaderIcon />
            <span>Loading...</span>
        </div>
    );
}

export default React.memo(Loader);
