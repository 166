import React, { useCallback, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import NP from '../../assets/images/NP.png';
import UrkP from '../../assets/images/UkrP.png';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import { useModal } from '../../utils/useModal';
import style from './PopupDelivery.module.scss';

function PopupDelivery() {
    const popupBody = useRef(null);
    const popup = useRef();
    const firstUpdate = useRef(true);
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);

        return () => document.removeEventListener('click', closeModal);
    }, [ popupBody?.current ]);

    useEffect(() => {
        if (initPopup) {
            disableBodyScroll(popupBody?.current);
        }
    }, [ initPopup ]);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current || firstUpdate.current) {
            firstUpdate.current = false;

            return;
        }
        if (!popupBody?.current.contains(e.target)) {
            dismiss();
            enableBodyScroll(popupBody?.current);
        }
    }, [ popupBody, firstUpdate ]);

    const closeBtn = useCallback(() => {
        dismiss();
        enableBodyScroll(popupBody?.current);
    }, [ popupBody, firstUpdate ]);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <section ref={popup} className={classNames(style['popup-delivery'], '--transparent')}>
                    <div  ref={popupBody} className={style['popup-delivery__inner']}>
                        <button className={style['popup-delivery__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>

                        <h2>
                            Швидка доставка ліків
                        </h2>

                        <p>
                            Доступні способи доставки залежать від
                            конкретного товару і його доступності у Вашому регіоні.
                        </p>

                        <div className={style['popup-delivery__grid']}>
                            <div>
                                <h4>
                                    Самовивіз із аптеки
                                </h4>
                                <p>
                                    Забирайте замовлення вже через 3 хвилини після його оформлення в
                                    будь-якому аптечному закладі-партнері, який є на сайті.
                                    Просто назвіть провізору номер замовлення.
                                </p>
                            </div>

                            <div>
                                <img
                                    data-src={NP} className={'lazyload'}
                                    alt='test' />
                                <p>
                                    Безкоштовно за умови оплати на сайті.
                                </p>

                                <p>
                                    Безкоштовно для замовлень від 999 грн.
                                </p>

                                <p>
                                    35 грн. для замовлень до 999 грн.
                                    <span>(при оплаті післяплатою +20 грн. незалежно від суми замовлення).</span>
                                </p>
                            </div>

                            <div>
                                <img
                                    data-src={UrkP} className={'lazyload'}
                                    alt='test' />

                                <h3> Вартість - безкоштовно.</h3>

                                <p>
                                    Увага! Доставка тільки у відділення Укрпошти, не у всі регіони України.
                                </p>
                            </div>

                            <div>
                                <h2>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    Кур'єр
                                </h2>

                                <h3>  Вартість – безкоштовно.</h3>

                                <p>
                                    Увага! Оплата лише готівкою при отриманні.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupDelivery);
