import React           from 'react';
import PropTypes       from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useModal }    from '../../utils/useModal';
import { useLoading }  from '../../utils/useLoading';
import Header          from '../../components/header';
import Footer          from '../../components/footer';
import NavigationPopup from '../../components/NavigationPopup';
import PopupPersonal   from '../../components/PopupPersonal';
import { MODALS }      from '../../constants';
import PopupDelivery   from '../../components/PopupDelivery';
import Loader          from '../../components/Loader';
// import PopupDeliveryNew from '../../components/PopupDeliveryNew';
import PopupStep from '../../components/PopupStep';
import PopupConsultation from '../../components/PopupConsultation';
import PopupBooking from '../../components/PopupBooking';
import PopupLogIn from '../../components/PopupLogIn';
import PopupRegistration from '../../components/PopupRegistration';
import PopupDragDrop from '../../components/PopupDragDrop';
import PopupOffer from '../../components/PopupOffer';
import { statusVisuallyImpairedSelector } from '../../store/selectors/VisuallyImpaired';

const POPUPS = {
    [MODALS.CATALOG_MODAL]      : <NavigationPopup />,
    [MODALS.CHANGE_PASSWORD]    : <PopupPersonal name={MODALS.CHANGE_PASSWORD} />,
    [MODALS.ADD_NEW_LIST]       : <PopupPersonal name={MODALS.ADD_NEW_LIST} />,
    [MODALS.POPUP_DELIVERY]     : <PopupDelivery />,
    [MODALS.POPUP_DELIVERY_NEW] : <PopupDelivery />,
    [MODALS.POPUP_STEP]         : <PopupStep />,
    [MODALS.POPUP_CONSULTATION] : <PopupConsultation />,
    [MODALS.POPUP_BOOKING]      : <PopupBooking />,
    [MODALS.POPUP_LOGIN]        : <PopupLogIn />,
    [MODALS.POPUP_REGISTER]     : <PopupRegistration />,
    [MODALS.POPUP_DRAG]         : <PopupDragDrop />,
    [MODALS.POPUP_OFFER]        : <PopupOffer />
};

function MainLayout({ children }) {
    const { activeModal } = useModal();
    const { loading } = useLoading();
    const status = useSelector(statusVisuallyImpairedSelector);

    const modal = POPUPS[activeModal?.id] || null;

    return (
        <div className={classNames({ '--defects': status })}>
            {modal}
            {
                loading &&  <Loader />
            }
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    );
}

MainLayout.propTypes = {
    children : PropTypes.node.isRequired
};

export default MainLayout;
