import React from 'react';

function GoogleIcon() {
    return (
        <svg
            width='26' height='26' viewBox='0 0 26 26'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_2165_63388)'>
                <path d='M5.76215 15.7121L4.85713 19.0906L1.54929 19.1606C0.560727 17.3271 0 15.2292 0 12.9999C0 10.8442 0.524266 8.81135 1.45356 7.02136H1.45427L4.39918 7.56127L5.68923 10.4885C5.41922 11.2757 5.27206 12.1207 5.27206 12.9999C5.27216 13.9542 5.44502 14.8685 5.76215 15.7121Z' fill='#FBBB00' />
                <path d='M25.7727 10.5714C25.922 11.3578 25.9998 12.17 25.9998 13C25.9998 13.9307 25.902 14.8386 25.7156 15.7143C25.0827 18.6943 23.4291 21.2964 21.1385 23.1378L21.1377 23.1371L17.4285 22.9479L16.9035 19.6707C18.4235 18.7793 19.6114 17.3843 20.2371 15.7143H13.2856V10.5714H20.3385H25.7727Z' fill='#518EF8' />
                <path d='M21.1379 23.1371L21.1386 23.1378C18.9108 24.9285 16.0807 26 13 26C8.04932 26 3.74505 23.2329 1.54932 19.1607L5.76218 15.7122C6.86002 18.6421 9.68645 20.7279 13 20.7279C14.4243 20.7279 15.7586 20.3428 16.9036 19.6707L21.1379 23.1371Z' fill='#28B446' />
                <path d='M21.2979 2.99284L17.0865 6.44069C15.9015 5.69999 14.5007 5.27211 13.0001 5.27211C9.61147 5.27211 6.73217 7.45352 5.68933 10.4886L1.45432 7.02142H1.45361C3.6172 2.85 7.97575 0 13.0001 0C16.1543 0 19.0465 1.12359 21.2979 2.99284Z' fill='#F14336' />
            </g>
            <defs>
                <clipPath id='clip0_2165_63388'>
                    <rect width='26' height='26' fill='white' />
                </clipPath>
            </defs>
        </svg>

    );
}

export default React.memo(GoogleIcon);
