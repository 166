export function saveToLocalStorage(key, data) {
    try {
        const dataJSON = JSON.stringify(data);

        localStorage.setItem(key, dataJSON);
    } catch (err) {
        console.log(`[ERROR] saveToLocalStorage ${key}`, err);
    }
}

export function getFromLocalStorage(key) {
    try {
        const data = localStorage.getItem(key);

        return data ? JSON.parse(data) : data;
    } catch (err) {
        console.log(`[ERROR] getFromLocalStorage ${key}`, err);
    }
}

export function removeFromLocalStorage(key) {
    try {
        localStorage.removeItem(key);
    } catch (err) {
        console.log(`[ERROR] getFromLocalStorage ${key}`, err);
    }
}

export function clearLocalStorage() {
    try {
        localStorage.clear();
    } catch (err) {
        console.log('[ERROR] clearLocalStorage', err);
    }
}

