/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import { useModal } from '../../utils/useModal';
import { errorOfferSelector, offerContentSelector } from '../../store/selectors/Offer';
import style from './PopupOffer.module.scss';

function PopupOffer() {
    const popupBody = useRef(null);
    const popup = useRef();
    const firstUpdate = useRef(true);
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);
    const offerContent = useSelector(offerContentSelector);
    const offerError = useSelector(errorOfferSelector);

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);

        return () => document.removeEventListener('click', closeModal);
    }, [ popupBody?.current ]);

    useEffect(() => {
        if (initPopup) {
            disableBodyScroll(popupBody?.current);
        }
    }, [ initPopup ]);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current.contains(e.target)) {
            enableBodyScroll(popupBody?.current);
            dismiss();
        }
    }, [ popupBody, dismiss ]);

    const closeBtn = useCallback(() => {
        dismiss();
        enableBodyScroll(popupBody?.current);
    }, [ popupBody, firstUpdate ]);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <section ref={popup} className={classNames(style['popup-offer'], '--transparent')}>
                    <div  ref={popupBody} className={style['popup-offer__inner']}>
                        <button className={style['popup-offer__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>
                        <div dangerouslySetInnerHTML={{ __html: offerContent || offerError }} />
                    </div>
                </section>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupOffer);
