import Base from './Base.js';

class Cart extends Base {
    list(id) {
        return this.apiClient.get({
            requestURL : `api/cart/session/${id}`
        });
    }
}

export default Cart;
