import Base from './Base.js';

class Orders extends Base {
    create(payload) {
        return this.apiClient.post({
            requestURL : 'api/orders',
            payload
        });
    }
}

export default Orders;
