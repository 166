import Base from './Base.js';

class CatalogTree extends Base {
    list(queryParams) {
        return this.apiClient.get({
            requestURL : 'api/catalog/tree',
            queryParams
        });
    }
}

export default CatalogTree;
