import React, { useCallback, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import { useModal } from '../../utils/useModal';
import img from '../../assets/images/drop.png';
import ArrowRight from '../../assets/icon/ArrowRight';
// import api from '../../apiSingleton';
import style from './PopupDragDrop.module.scss';

function PopupDragDrop() {
    const popupBody = useRef(null);
    const popup = useRef();
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);
    const [ dragOver, setDragOver ] = useState(false);
    const [ upload, setUpload ] = useState('');
    const [ uploadImg, setUploadImg ] = useState();
    const [ success, setSuccess ] = useState(false);

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);

        return () =>  document.removeEventListener('click', closeModal);
    }, [ popupBody?.current ]);

    useEffect(() => {
        if (initPopup) {
            disableBodyScroll(popupBody?.current);
        }
    }, [ initPopup ]);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current.contains(e.target)) {
            enableBodyScroll(popupBody?.current);
            dismiss();
        }
    }, [ popupBody, dismiss ]);

    const closeBtn = useCallback(() => {
        enableBodyScroll(popupBody?.current);
        dismiss();
    }, [ popupBody, dismiss ]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 27) {
            enableBodyScroll(popupBody?.current);
            dismiss();
        }
    }, [ dismiss ]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ handleKeyDown ]);

    const handleDragEnter = useCallback(e => {
        e.preventDefault();
        setDragOver(true);
    }, [ dragOver ]);

    const handleDragLeave = useCallback(e => {
        e.preventDefault();
        setDragOver(false);
    }, [ dragOver ]);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = useCallback(e => {
        e.preventDefault();
        setDragOver(false);

        const file = e.dataTransfer.files[0];

        handleFileUpload(file);
    }, [ dragOver ]);

    const handleFileUpload = async (file) => {
        setUpload(file);
        const reader = new FileReader();

        reader.onload = async function (e) {
            await setUploadImg(e.target.result);
        };
        await reader.readAsDataURL(file);
    };

    const handleClick = useCallback(async () => {
        const formData = new FormData();

        formData.append('file', upload, upload.name);
        //
        try {
            await fetch(`${ window.APP_CONF.API_URL}/api/file/upload`, {
                body   : formData,
                method : 'POST'
            });

            await setSuccess(true);
        } catch (er) {
            console.error('Ошибка загрузки файла:', er);
            setSuccess(false);
        }
    }, [ uploadImg ]);

    const handleClickUpload = () => {
        const fileInput = document.createElement('input');

        fileInput.type = 'file';
        fileInput.addEventListener('change', (e) => {
            const file = e.target.files[0];

            handleFileUpload(file);
        });
        fileInput.click();
    };

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <section ref={popup} className={classNames(style['drag-drop'], '--transparent')}>
                    <div ref={popupBody} className={style['drag-drop__inner']}>
                        <button className={style['drag-drop__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>

                        <img
                            className={style['drag-drop__img']}
                            src={img}
                            alt='test' />

                        <h2>
                            Якщо лікар написав рецепт на папері, та тобі важко з усім розібратись, з радістю в цьому допоможемо.
                        </h2>

                        <p>
                            Свотографуй рецепт, та відправ нам фото. Наші найкращі фармацевти розшифрують та створять замовлення для тебе.
                        </p>

                        { success ? <h3>Фото успішно відправлено</h3> :
                        <>
                            <button
                                className={style['drag-drop__drop']}
                                        /* eslint-disable-next-line react/jsx-no-bind */
                                onDragEnter={handleDragEnter}
                                        /* eslint-disable-next-line react/jsx-no-bind */
                                onDragLeave={handleDragLeave}
                                        /* eslint-disable-next-line react/jsx-no-bind */
                                onDragOver={handleDragOver}
                                        /* eslint-disable-next-line react/jsx-no-bind */
                                onDrop={handleDrop}
                                        /* eslint-disable-next-line react/jsx-no-bind */
                                onClick={handleClickUpload}
                                    >
                                { uploadImg ?  <img
                                    className={style['drag-drop__img']} style={{
                                            width  : 27,
                                            height : 27
                                        }}
                                    src={uploadImg} alt='test'
                                            />
                                            :
                                <svg
                                    xmlns='http://www.w3.org/2000/svg' width='37' height='37'
                                    viewBox='0 0 37 37' fill='none'
                                    style={{ marginBottom: 19 }}
                                        >
                                    <g clipPath='url(#clip0_2507_49280)'>
                                        <path d='M23.8412 0.207833C23.6715 0.0291445 23.4389 -0.078125 23.1979 -0.078125H8.76419C6.1008 -0.078125 3.90234 2.11158 3.90234 4.77469V32.0688C3.90234 34.7322 6.1008 36.9219 8.76419 36.9219H28.3544C31.0178 36.9219 33.2163 34.7322 33.2163 32.0688V10.3962C33.2163 10.1639 33.109 9.94056 32.9571 9.77063L23.8412 0.207833ZM24.1003 3.07673L30.2045 9.48467H26.2364C25.0567 9.48467 24.1003 8.53731 24.1003 7.35763V3.07673ZM28.3544 35.1345H8.76419C7.09305 35.1345 5.68979 33.7493 5.68979 32.0688V4.77469C5.68979 3.10354 7.08401 1.70932 8.76419 1.70932H22.3129V7.35763C22.3129 9.52927 24.0648 11.2721 26.2364 11.2721H31.4288V32.0688C31.4288 33.7493 30.0346 35.1345 28.3544 35.1345Z' fill='#42464F' />
                                        <path d='M25.9144 28.9688H11.2038C10.7123 28.9688 10.3101 29.3707 10.3101 29.8625C10.3101 30.3539 10.7123 30.7562 11.2038 30.7562H25.9234C26.4149 30.7562 26.8171 30.3539 26.8171 29.8625C26.8171 29.3707 26.4149 28.9688 25.9144 28.9688Z' fill='#42464F' />
                                        <path d='M13.9837 19.3495L17.6658 15.3902V25.1497C17.6658 25.6412 18.0681 26.0434 18.5595 26.0434C19.0513 26.0434 19.4533 25.6412 19.4533 25.1497V15.3902L23.1354 19.3495C23.3141 19.537 23.5467 19.6355 23.7878 19.6355C24.0023 19.6355 24.2259 19.555 24.3956 19.3941C24.7532 19.0545 24.78 18.4914 24.4405 18.134L19.2032 12.5125C19.0332 12.3338 18.8009 12.2266 18.5508 12.2266C18.3004 12.2266 18.0681 12.3338 17.8984 12.5125L12.6611 18.134C12.3215 18.4914 12.3484 19.0633 12.7057 19.3941C13.0812 19.7337 13.6441 19.7069 13.9837 19.3495Z' fill='#42464F' />
                                    </g>
                                    <defs>
                                        <clipPath id='clip0_2507_49280'>
                                            <rect width='37' height='37' fill='white' />
                                        </clipPath>
                                    </defs>
                                </svg>
                                        }

                                <p className={style.drop_title}>
                                    Перетягніть файли для завантаження
                                </p>

                                {
                                            upload &&
                                            <p className={style.drop_title}>
                                                { upload.name }
                                            </p>
                                        }

                                <p className={style.drop_desc}>
                                    Або
                                    <span>
                                        Відкрийте
                                    </span>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    на комп'ютері
                                </p>

                                <span className={style.span}>
                                    You cau upload jpg, png. Max size 200mb
                                </span>
                            </button>

                            <button
                                className={style.button}
                                onClick={handleClick}
                                style={{
                                    pointerEvents : upload ? 'all' : 'none',
                                    opacity       : upload ? '1' : '0.5'
                            }}>
                                Продовжити
                                <ArrowRight />
                            </button>
                        </>
                        }
                    </div>
                </section>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupDragDrop);
