import ACTIONS from '../constants/actionsTypes';

const initialState = {
    status : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_VISUALLY_IMPAIRED:
            return {
                ...state,
                status : data
            };
        default:
            return state;
    }
};
