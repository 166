import Base from './Base.js';

class FilterCatalog extends Base {
    list(id) {
        return this.apiClient.get({
            requestURL : `api/filters/catalog/${id}`
        });
    }
}

export default FilterCatalog;
