import Base from './Base.js';

class CatalogTree extends Base {
    list(id, queryParams, showcase = 4) {
        // eslint-disable-next-line no-param-reassign
        queryParams = { catalogId: id, ...queryParams };

        return this.apiClient.get({
            requestURL : `api/product/filter/showcase/${showcase}`,
            queryParams
        });
    }
}

export default CatalogTree;
