import Base from './Base.js';

class Authenticate extends Base {
    createToken(payload) {
        return this.apiClient.post({
            requestURL : 'auth',
            payload    : payload || {
                username : 'admin@admin.com',
                password : '123456'
            }
        });
    }
}

export default Authenticate;
