import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '../../assets/icon/CloseIcon';
// import SearchIcon from '../../assets/icon/SearchIcon';
import { getCities } from '../../store/actions/Cities';
import { citiesListSelector, isLoadingCitiesSelector } from '../../store/selectors/Cities';

import Spinner from '../Spinner';
import { getCurrentCity, setDistrict } from '../../store/actions/City';
import { citySelector } from '../../store/selectors/City';
import SearchIcon from '../../assets/icon/SearchIcon';
import style from './LocationPopup.module.scss';

function LocationPopup({ event, classTitle, classBtn }) {
    const dispatch = useDispatch();
    const citiesList = useSelector(citiesListSelector);
    const { city } = useSelector(citySelector);
    const loading = useSelector(isLoadingCitiesSelector);
    const [ isOpen, setIsOpen ] = useState(false);
    const input = useRef(null);
    const [ cities, setCities ] = useState([]);
    const popupRef = useRef(null);
    const [ allCitiesList, setAllCities ] = useState(false);

    useEffect(() => {
        setCities(citiesList);
    }, [ citiesList ]);


    const changeCity = useCallback(({ target }) => {
        if (target.closest('button')) {
            const id = +target.closest('button').id;
            const selectedCity = cities.find(c => c.id === id);

            setAllCities(false);

            dispatch(getCurrentCity(selectedCity));
            dispatch(setDistrict({}));
            localStorage.setItem('city', JSON.stringify(selectedCity));
        }
    }, [ cities ]);

    // eslint-disable-next-line no-shadow
    const handleOutsideClick = useCallback(({ target }) => {
        if (popupRef.current && !popupRef.current.contains(target)) {
            setIsOpen(false);
            event(false);
        }
    }, [ isOpen ]);

    const fetchData = useCallback(async () => {
        await dispatch(getCities());
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        if (!cities.length > 0) fetchData();

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const togglePopup = useCallback(() => {
        setIsOpen(!isOpen);
        event(!isOpen);
    }, [ isOpen ]);

    const searchHandle = useCallback(({ target }) => {
        setAllCities(false);
        window.scrollTo({
            top      : 0,
            behavior : 'smooth'
        });

        const search = target.value;

        const foundObjects = citiesList.filter(obj => {
            return (
                obj.nameEn.toLowerCase().startsWith(search.toLowerCase()) ||
                obj.nameRu.toLowerCase().startsWith(search.toLowerCase()) ||
                obj.nameUa.toLowerCase().startsWith(search.toLowerCase())
            );
        });

        setCities(foundObjects);
    }, [ citiesList ]);

    const allCities = useCallback(() => {
        setCities(citiesList);
        input.current.value = '';
        setAllCities(true);
    }, []);

    const renderSpinnerOrEmptyList = useCallback(() => {
        if (loading) {
            return (
                <CSSTransition
                    timeout={300}
                    in={loading}
                    unmountOnExit
                    classNames='item'
                    mode={'out-in'}
                >
                    <Spinner />
                </CSSTransition>
            );
        }

        return <h2 className={style['empty-list-title']}>Поки що немає міст</h2>;
    }, [ loading ]);

    return (
        <div className={classNames(classTitle, style['location-popup'])} ref={popupRef}>
            <div className={style['location-popup__inner']}>
                <button
                    onClick={togglePopup}
                    className={classNames(classBtn, style.close)}>

                    <CloseIcon />
                </button>
                <h2>
                    Оберіть ваше місто
                </h2>
                <div className={style['location-popup__country']} onClick={changeCity}>
                    {
                        (cities?.length > 0) ?
                            <>
                                {
                                    allCitiesList ?

                                            cities?.map((elem) => (
                                                <button
                                                    className={+city?.id === +elem?.id ? style.active : ''}
                                                    name={elem?.nameUa}
                                                    id={elem?.id}
                                                    key={elem?.id}>{elem?.nameUa}</button>
                                        ))

                                    :

                                    cities?.map((elem, i) => (
                                    i <= 7  &&
                                    <button
                                        className={+city?.id === +elem?.id ? style.active : ''}
                                        name={elem?.nameUa}
                                        id={elem?.id}
                                        key={elem?.id}>{elem?.nameUa}</button>
                                    ))

                                }
                            </>
                              :  renderSpinnerOrEmptyList()
                    }
                </div>

                <div className={style['location-popup__search']}>
                    <label htmlFor='#'>
                        <button>
                            <SearchIcon />
                        </button>
                        <input
                            ref={input}
                            defaultValue={''}
                            onChange={searchHandle}
                            type={'text'}
                        />
                    </label>

                    {/* eslint-disable-next-line react/jsx-no-bind */}

                    <button
                        className={style.all} onClick={allCities} style={{
                        display : !allCitiesList ? 'block' : 'none'
                    }}>
                        Показати всі міста
                    </button>
                </div>
            </div>
        </div>
    );
}

LocationPopup.propTypes = {
    event      : PropTypes.func,
    classTitle : PropTypes.string,
    classBtn   : PropTypes.string
};

LocationPopup.defaultProps = {
    event      : null,
    classTitle : '',
    classBtn   : ''
};

export default React.memo(LocationPopup);
