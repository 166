export const ADDRESS        = 'ADDRESS';
export const FIRST_NAME     = 'FIRST_NAME';
export const LAST_NAME      = 'LAST_NAME';
export const SURNAME        = 'SURNAME';
export const PHONE_NUMBER   = 'PHONE_NUMBER';
export const METHOD         = 'METHOD';
export const FLAT           = 'FLAT';
export const FLOOR          = 'FLOOR';
export const ENTRANCE       = 'ENTRANCE';
export const CLIENT_COMMENT = 'CLIENT_COMMENT';
export const PAYMENT_TYPE   = 'PAYMENT_TYPE';
