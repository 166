import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from './LoadingContext';

export function LoadingProvider({ children }) {
    const [ loading, setLoading ] = useState(true);
    const value = { loading, setLoading };

    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    );
}

LoadingProvider.propTypes = {
    children : PropTypes.oneOfType([ PropTypes.node, PropTypes.array ])
};

LoadingProvider.defaultProps = {
    children : null
};
