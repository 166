import React from 'react';

function EyeIcon() {
    return (
        <svg
            style={{ minWidth: '1.5rem' }} width='24' height='25'
            viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M20.4466 8.45099C20.1885 8.22904 19.9291 8.01703 19.669 7.81396L16.6362 10.8468C16.8209 11.3637 16.922 11.9201 16.922 12.4997C16.922 15.2136 14.714 17.4216 12.0001 17.4216C11.4205 17.4216 10.8641 17.3206 10.3472 17.1357L8.07104 19.4119C9.40022 19.9571 10.7186 20.2341 12.0001 20.2341C13.5309 20.2341 15.1141 19.8405 16.7056 19.0643C17.9554 18.4547 19.214 17.6082 20.4466 16.5485C22.5304 14.7567 23.8141 12.9849 23.8677 12.9103C24.0442 12.6651 24.0442 12.3345 23.8677 12.0892C23.8141 12.0146 22.5304 10.2428 20.4466 8.45099Z' fill='#00A0E3' />
            <path d='M12.0002 16.0154C13.9387 16.0154 15.5158 14.4383 15.5158 12.4998C15.5158 12.3312 15.5031 12.1654 15.48 12.0031L11.5034 15.9797C11.6658 16.0028 11.8315 16.0154 12.0002 16.0154Z' fill='#00A0E3' />
            <path d='M23.7939 0.705909C23.5194 0.431364 23.0742 0.431364 22.7996 0.705909L17.2755 6.22999C17.0854 6.12649 16.8953 6.02792 16.7054 5.93534C15.1139 5.15909 13.5307 4.76553 11.9999 4.76553C10.4692 4.76553 8.88597 5.15909 7.29448 5.93529C6.04471 6.5449 4.78603 7.39136 3.55346 8.4511C1.4697 10.2429 0.185987 12.0147 0.132362 12.0893C-0.0441208 12.3345 -0.0441208 12.6651 0.132362 12.9104C0.185987 12.985 1.46965 14.7568 3.55346 16.5486C4.19203 17.0976 4.83764 17.5888 5.48535 18.02L0.206002 23.2995C-0.0685893 23.574 -0.0685893 24.0193 0.206002 24.2938C0.343298 24.4311 0.523203 24.4998 0.703155 24.4998C0.883107 24.4998 1.06306 24.4311 1.20031 24.2938L23.7939 1.70021C24.0685 1.42567 24.0685 0.980453 23.7939 0.705909ZM7.07811 12.4999C7.07811 9.78595 9.28604 7.57801 11.9999 7.57801C13.1039 7.57801 14.1241 7.94336 14.946 8.55948L13.9373 9.56822C13.3814 9.1996 12.7155 8.98425 11.9999 8.98425C10.0614 8.98425 8.48435 10.5614 8.48435 12.4999C8.48435 13.2153 8.69969 13.8812 9.06831 14.4372L8.05957 15.4459C7.44345 14.624 7.07811 13.6038 7.07811 12.4999Z' fill='#00A0E3' />
        </svg>
    );
}

export default React.memo(EyeIcon);
