import React, { useCallback, useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { useModal } from '../../utils/useModal';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import Button from '../Button';
import { MODALS } from '../../constants';
import style from './PopupStep.module.scss';

function PopupStep() {
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);
    const { toggleModal } = useModal();

    useEffect(() => {
        setInitPopup(true);
        disableBodyScroll(document.body);

        return () => {
            enableBodyScroll(document.body);
        };
    }, []);

    const closeModal = useCallback((e) => {
        if (e.target === e.currentTarget) {
            dismiss();
            enableBodyScroll(document.body);
        }
    }, [ dismiss ]);

    const closeBtn = useCallback(() => {
        dismiss();
        enableBodyScroll(document.body);
    }, [ dismiss ]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 27) {
            dismiss();
            enableBodyScroll(document.body);
        }
    }, [ dismiss ]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ handleKeyDown ]);

    const takePhoto = useCallback(() => {
        // eslint-disable-next-line no-shadow
        const checkToken = document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1];

        if (checkToken) {
            toggleModal({ id: MODALS.POPUP_DRAG });
        } else  {
            const expiryDate = new Date();

            expiryDate.setTime(expiryDate.getTime() + (2 * 24 * 60 * 60 * 1000));
            document.cookie = `recipe=true; expires=${expiryDate.toUTCString()}; path=/`;
            toggleModal({ id: MODALS.POPUP_LOGIN });
        }
    }, []);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <div className={classNames(style['popup-step'], '--transparent')} onClick={closeModal}>
                    <div className={style['popup-step__inner']}>
                        <div className={style['popup-step__inner-row']}>
                            <div className={style['popup-step__row']}>
                                <span className={style.span}>1</span>
                                <h4>Додай до кошика все необхідне по списку</h4>
                            </div>
                            <div className={style['popup-step__row']}>
                                <span className={style.span}>2</span>
                                <h4>Обери найближчу аптеку де є все.</h4>
                            </div>
                            <div className={style['popup-step__row']}>
                                <span className={style.span}>3</span>
                                <div>
                                    <h4>Забронюй та забери вже за 3 хвилини.</h4>
                                    <p>Або ми доставимо коли і куди тобі зручно.</p>
                                </div>
                            </div>
                        </div>
                        <button className={style['popup-step__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>
                        <div className={style['popup-step__down']}>
                            <h4>Немає часу оформити замовлення?</h4>
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <Button name={'надішли нам фото рецепту!'} clsTitle={style.button} event={takePhoto} />
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupStep);
