import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import ArrowRight from '../../assets/icon/ArrowRight';
// import FacebookIcon from '../../assets/icon/FacebookIcon';
import GoogleIcon from '../../assets/icon/GoogleIcon';
import api from '../../apiSingleton';
import Spinner from '../Spinner';
import { useModal } from '../../utils/useModal';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import CheckBoxIcon from '../../assets/icon/CheckBoxIcon';
import useMediaQuery from '../../hooks/useMediaQuery';
import { MODALS } from '../../constants';
import History from '../../history';
import style from './PopupRegistration.module.scss';

function PopupRegistration() {
    const email = useRef();
    const password = useRef();
    const [ loading, setLoading ] = useState(false);
    const popupBody = useRef(null);
    const popup = useRef();
    const { dismiss, toggleModal } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);
    const [ change, setChange ] = useState(false);
    const [ error, setError ] = useState(false);
    const isTablet = useMediaQuery('(min-width: 768px)');

    const [ event, updateEvent ] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        { email: '', password: '', name: '', password2: '', phone: '', checked: false }
    );

    useEffect(() => {
        document.addEventListener('click', closeModal);

        setInitPopup(true);

        return () => document.removeEventListener('click', closeModal);
    }, [ popupBody?.current ]);

    useEffect(() => {
        if (initPopup) {
            disableBodyScroll(popupBody?.current);
        }
    }, [ initPopup ]);

    const openRegister = useCallback(() => {
        clearAllBodyScrollLocks();
        dismiss();
        toggleModal({ id: MODALS.POPUP_LOGIN });
    }, []);

    const closeModal = useCallback(({ target }) => {
        if (popupBody?.current && !popupBody?.current.contains(target)) {
            enableBodyScroll(document.body);
            clearAllBodyScrollLocks();
            dismiss();
        }
    }, [ popupBody ]);

    const validateEmail = useCallback((eml) => {
        const re = /\S+@\S+\.\S+/;


    return re.test(eml);
    }, []);

    const closeBtn = useCallback(() => {
        dismiss();
        enableBodyScroll(document.body);
    }, [ popupBody ]);

    const requestForm = useCallback(
        async (e) => {
            e.preventDefault();

            setChange(true);

            if (
                validateEmail(event.email) &&
                event.password.length >= 5 &&
                event.password === event.password2 &&
                event.name.length > 1 &&
                event.checked
            ) {
                setLoading(true);

                popupBody.current.scrollTo(0, 0);

                try {
                    const obj = { ...event };

                    obj.phone = obj.phone.replaceAll(' ', '');

                    obj.plainPassword = obj.password;

                    delete obj.password;
                    delete obj.password2;

                    await api.registration.createToken(obj);

                    event.phone = event.phone.replaceAll(' ', '');
                    event.username = event.email;
                    delete event.email;

                    const request = await api.authenticate.createToken(event);

                    const { token, id } = request;

                    if (token) {
                        if (error) {
                            setError(false);
                        }
                        api.apiClient.setToken(token, id);
                        enableBodyScroll(document.body);
                        clearAllBodyScrollLocks();
                        dismiss();
                        setLoading(false);
                        History.push('/personal-area');
                    }
                } catch ({ error: errorMessage, field }) {
                    setError({
                        errorMessage,
                        field
                    });
                    setLoading(false);
                }
            }
        },
        [ event ]
    );

    const changeHandle = useCallback(({ target }) => {
        setError(false);
        updateEvent({ [target.name]: target.name !== 'checked' ? target.value : target.checked });
    }, []);

    const changePhone = useCallback((e) => {
        setError(false);
        let value = e.target.value.replace(/\D/g, '');

        if (!value.startsWith('+380')) {
            value = `+380${  value.slice(3)}`;
        }

        let formattedValue = value.replace(/^(\+\d{3})\s?(\d{0,2})?\s?(\d{0,3})?\s?(\d{0,2})?\s?(\d{0,2})?$/, (_, p1, p2, p3, p4, p5) => {
            let result = p1;

            if (p2) result += ` ${  p2}`;
            if (p3) result += ` ${  p3}`;
            if (p4) result += ` ${  p4}`;
            if (p5) result += ` ${  p5}`;

            return result;
        });

        if (formattedValue.length > 17) {
            formattedValue = formattedValue.slice(0, 17);
        }

        e.target.value = formattedValue;
        updateEvent({ phone: formattedValue });
    }, []);


    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <section className={style['popup-registration']}  ref={popup}>
                    <div className={classNames(style['popup-registration__wrapper'], { [style.loading]: loading })} ref={popupBody}>
                        <button className={style['popup-registration__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>

                        { loading  && <Spinner /> }

                        <h2>Реєстрація</h2>

                        <div className={style['popup-registration__inner']}>
                            <div>
                                <form action='/' onSubmit={requestForm}>
                                    <label>
                                        <input
                                            ref={email}
                                            name={'name'}
                                            type='text'
                                            placeholder={'Ім’я'}
                                            onChange={changeHandle}
                                        />

                                        {
                                            (change && !event.name.length > 0) && <span>Enter correct name</span>
                                        }
                                    </label>
                                    <label htmlFor='#'>
                                        <input
                                            placeholder='+380_'
                                            name='phone'
                                            maxLength={17}
                                            value={event?.phone}
                                            onChange={changePhone}
                                        />

                                        {
                                            (change && event.phone.length !== 17) && <span>Enter correct phone</span>
                                        }
                                    </label>
                                    <label>
                                        <input
                                            ref={email}
                                            name={'email'}
                                            type='text'
                                            placeholder={'Електронна пошта'}
                                            onChange={changeHandle}
                                        />
                                        <>
                                            {
                                                error.field === 'email' ? <span>{error.errorMessage}</span> : ''
                                            }
                                        </>
                                        {
                                            (change && !validateEmail(event.email)) && <span>Enter correct email</span>
                                        }
                                    </label>
                                    <label>
                                        <input
                                            ref={password}
                                            name={'password'}
                                            type='password'
                                            placeholder={'Пароль'}
                                            onChange={changeHandle}
                                        />
                                        {
                                             (change && event.password.length < 5) && <span>Password must be at least 4 characters</span>
                                         }
                                    </label>

                                    <label>
                                        <input
                                            ref={password}
                                            name={'password2'}
                                            type='password'
                                            placeholder={'Підтвердження паролю'}
                                            onChange={changeHandle}
                                        />

                                        {
                                            (change && event.password2 !== event.password) && <span>Password mismatch</span>
                                        }
                                    </label>
                                    <fieldset className={style.checkbox}>
                                        <input
                                            type='checkbox'
                                            id={'check'}
                                            name='checked'
                                            onChange={changeHandle}
                                        />
                                        <label htmlFor={'check'} className={classNames({ [style.error_check]: change && !event.checked })}>
                                            Натискаючи кнопку «Зареєструватися», ви даєте згоду на обробку особистих даних.
                                        </label>
                                    </fieldset>
                                    <button className={classNames({ [style.error]: error })}>
                                        Зареєструватися
                                        <ArrowRight />
                                    </button>
                                </form>

                                <p>
                                    Вже зареєстровані?
                                    <button onClick={openRegister}>
                                        Увійти
                                    </button>
                                </p>

                                <div className={style.line}>
                                    Або
                                </div>

                                <div className={style.social}>
                                    {/* <button>
                                        <FacebookIcon />
                                        Продовжити  з Facebook
                                    </button> */}
                                    <a href={'https://admin.farmacia.ua/connect/google'}>
                                        <GoogleIcon />
                                        Продовжити  з Google
                                    </a>
                                </div>
                            </div>

                            { isTablet &&  <div className={style.right__block}>
                                <h2>З обліковим записом Farmacia ви можете:</h2>

                                <div className={style.right__block__inner}>
                                    <div>
                                        <div>
                                            <CheckBoxIcon />
                                        </div>
                                        <p>
                                            Підписатися на ваші улюблені <br /> продукти
                                        </p>
                                    </div>

                                    <div>
                                        <div>
                                            <CheckBoxIcon />
                                        </div>
                                        <p>
                                            Відстежувати та керувати своїми <br /> замовленнями
                                        </p>
                                    </div>

                                    <div>
                                        <div>
                                            <CheckBoxIcon />
                                        </div>
                                        <p>
                                            Отримувати ексклюзивні <br /> пропозиції та знижки
                                        </p>
                                    </div>

                                    <div>
                                        <div>
                                            <CheckBoxIcon />
                                        </div>
                                        <p>
                                            Створювати кілька списків <br /> бажань
                                        </p>
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                </section>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupRegistration);
