import ApiClient              from './ApiClient';
import AuthenticateApi        from './Authenticate';
import RegistrationApi        from './Registration';
import Products               from './Products';
import CatalogTree            from './CatalogTree';
import Catalog                from  './Catalog';
import UploadImgFile          from './UploadImg';
import FilterCatalog          from './FilterCatalog';
import PersonalArea           from './PersonalArea';
import Cart                   from './Cart';
import ProductRecommendations from './ProductRecommendations';
import AddInCart              from './AddInCart';
import Orders                 from './Orders';
import HeroBanner             from './HeroBanner';
import Cities                 from './Cities';
import PublicOffer            from './publicOffer';
import Checkout               from './Checkout';
import Payment                from './Payment';

export default function apiConstruct(config) {
    const api = new ApiClient(config.API_URL);

    return {
        apiClient              : api,
        authenticate           : new AuthenticateApi({ apiClient: api }),
        registration           : new RegistrationApi({ apiClient: api }),
        products               : new Products({ apiClient: api }),
        catalogTree            : new CatalogTree({ apiClient: api }),
        catalog                : new Catalog({ apiClient: api }),
        FilterCatalog          : new FilterCatalog({ apiClient: api }),
        cart                   : new Cart({ apiClient: api }),
        addInCart              : new AddInCart({ apiClient: api }),
        orders                 : new Orders({ apiClient: api }),
        productRecommendations : new ProductRecommendations({ apiClient: api }),
        heroBanner             : new HeroBanner({ apiClient: api }),
        cities                 : new Cities({ apiClient: api }),
        uploadImgFile          : new UploadImgFile({ apiClient: api }),
        publicOffer            : new PublicOffer({ apiClient: api }),
        checkout               : new Checkout({ apiClient: api }),
        personalArea           : new PersonalArea({ apiClient: api }),
        payment                : new Payment({ apiClient: api })
    };
}
