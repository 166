import React from 'react';

function MarkerIcon() {
    return (
        <svg
            style={{ minWidth: '0.938rem' }}
            width='15' height='17' viewBox='0 0 15 17'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.00593 16.3979C8.13123 16.2735 9.65124 14.7528 11.1406 12.7655C11.8853 11.7719 12.624 10.6594 13.1768 9.54483C13.729 8.43173 14.1 7.3082 14.1 6.29457C14.0999 3.03372 11.1282 0.4 7.5 0.4C3.87176 0.4 0.9 3.03372 0.9 6.29457C0.9 7.3082 1.27104 8.43173 1.82317 9.54483C2.37603 10.6594 3.11469 11.7719 3.85937 12.7655C5.34876 14.7528 6.86877 16.2735 6.99407 16.3979C7.26572 16.6676 7.73478 16.6671 8.00593 16.3979ZM8.00593 16.3979L7.93548 16.3269L8.00594 16.3979C8.00594 16.3979 8.00594 16.3979 8.00593 16.3979ZM10.816 10.924C9.75917 12.4998 8.46934 14.0003 7.5 15.0332C6.53089 14.0006 5.24105 12.5001 4.18419 10.9243C3.64245 10.1165 3.16321 9.29076 2.81969 8.49955C2.47575 7.70737 2.27053 6.95581 2.27053 6.29463C2.27053 3.74054 4.60542 1.6435 7.5 1.6435C10.3946 1.6435 12.7295 3.74054 12.7295 6.2946C12.7295 6.95581 12.5243 7.70735 12.1804 8.49949C11.8369 9.29065 11.3577 10.1163 10.816 10.924Z' fill='#009EF7' stroke='#00A0E3'
                strokeWidth='0.2' />
            <path
                d='M7.49953 3.15525C5.6409 3.15525 4.129 4.66719 4.129 6.52581C4.129 8.38443 5.64094 9.89637 7.49953 9.89637C9.35812 9.89637 10.87 8.38443 10.87 6.52581C10.87 4.66719 9.35812 3.15525 7.49953 3.15525ZM7.49953 8.52575C6.39683 8.52575 5.49962 7.62851 5.49962 6.52581C5.49962 5.42311 6.39687 4.52587 7.49953 4.52587C8.60219 4.52587 9.49943 5.42311 9.49943 6.52581C9.49943 7.62851 8.60219 8.52575 7.49953 8.52575Z' fill='#009EF7' stroke='#00A0E3'
                strokeWidth='0.2' />
        </svg>
    );
}

export default React.memo(MarkerIcon);
