import Base from './Base.js';

class ProductRecommendations extends Base {
    list(city = 1) {
        return this.apiClient.get({
            requestURL : `api/product/random/showcase/${city}/get`
        });
    }
}

export default ProductRecommendations;
