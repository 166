import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames                                          from 'classnames';
import { disableBodyScroll, enableBodyScroll }             from 'body-scroll-lock';
import { CSSTransition }                                   from 'react-transition-group';
import PropTypes                                           from 'prop-types';
import { useDispatch } from 'react-redux';
import inputStyle                                          from '../PersonalData/PersonalData.module.scss';
import ShowPassIcon                                        from '../../assets/icon/ShowPassIcon';
import { useModal }                                        from '../../utils/useModal';
import ClosePopupIcon                                      from '../../assets/icon/ClosePopupIcon';
import CheckBox                                            from '../CheckBox';
import { personalAreaChangePassword }                      from '../../store/actions/PersonalArea';
import style                                               from './PopupPersonal.module.scss';

function PopupPersonal({ name }) {
    const pass = useRef(null);
    const oldPassword = useRef(null);
    const popupBody = useRef(null);
    const dispatch = useDispatch();
    const popup = useRef();
    const firstUpdate = useRef(true);
    const [ error, setError ] = useState('');
    const [ success, setSuccess ] = useState('');
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);
    const [ passwordParams, setPasswordParams ] = useState({
        'current_password' : '',
        'new_password'     : ''
    });

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);
        disableBodyScroll(document.body);

        return () => document.removeEventListener('click', closeModal);
    }, []);

    const handlePassword = useCallback(({ target }) => {
        const value = target.value;

        const currentName = target.name;

        setPasswordParams((item) => (
            { ...item, ...{ [currentName]: value } }
        ));
    }, [ passwordParams ]);

    const handleChangePassword = useCallback(async () => {
        if (name === 'CHANGE_PASSWORD') {
            if (pass.current.value.length < 5) {
                setError('Новий Пароль має мати 5 або більше символів');

                return;
            }
            try {
                await dispatch(personalAreaChangePassword({
                    id : 1,
                    ...passwordParams
                }));

                setSuccess('Done');

                await setError('');
            } catch ({ message }) {
                setError(message);
                setSuccess('');
            }
        }
    }, [ passwordParams ]);


    useEffect(() => {
        if (!success) return;

        setTimeout(() => {
            dismiss();
            enableBodyScroll(document.body);
        }, 2000);
    }, [ success ]);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current || firstUpdate.current) {
            firstUpdate.current = false;

            return;
        }
        if (!popupBody?.current.contains(e.target)) {
            dismiss();
            enableBodyScroll(document.body);
        }
    }, [ popupBody, firstUpdate ]);

    const closeBtn = useCallback(() => {
        dismiss();
        enableBodyScroll(document.body);
    }, [ popupBody, firstUpdate ]);

    const showPass = useCallback((e) => {
        if (pass.current.type === 'password') {
            pass.current.type = 'text';
        } else {
            pass.current.type = 'password';
        }

        e.target.closest('button').classList.toggle(style.active);
    }, [ pass ]);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <div className={classNames(style['change-password'], 'popup')}  ref={popup}>
                    <div ref={popupBody} className={style['change-password__inner']}>
                        <h2>
                            { name === 'CHANGE_PASSWORD' ? 'Зміна пароля' : 'Новий список' }
                        </h2>

                        <button className={style['change-password__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>

                        <div className={style['change-password__label']}>
                            {
                                name === 'CHANGE_PASSWORD' ?
                                    <>
                                        <label htmlFor='#' ref={oldPassword} className={inputStyle.label}>
                                            Старий пароль <span>*</span>

                                            <input
                                                type='password'
                                                placeholder={'Старий пароль*'}
                                                name={'current_password'}
                                                className={inputStyle.input}
                                                onChange={handlePassword}
                                            />
                                        </label>
                                        <label htmlFor='#' className={inputStyle.label}>
                                            Новий пароль <span>*</span>

                                            <button onClick={showPass}>
                                                <ShowPassIcon />
                                            </button>

                                            <input
                                                type='password'
                                                name={'new_password'}
                                                ref={pass}
                                                onChange={handlePassword}
                                                placeholder={'Новий пароль*'}
                                                className={inputStyle.input}
                                            />

                                            { error && <span className={style.error}>Невідповідність поточного пароля</span>}
                                        </label>
                                        <button className={style.password}>
                                            Нагадати пароль
                                        </button>
                                    </>
                                    :
                                    <>
                                        <label htmlFor='#' className={inputStyle.label}>
                                            Назва нового списку
                                            <input type='text' placeholder={'Мій список'} className={inputStyle.input} />
                                        </label>

                                        <CheckBox id={'list'} name={'Зробити списком за замовчуванням'} />
                                    </>
                            }

                            <div className={style['change-password__btn-inner']}>
                                {/* eslint-disable-next-line no-sequences */}
                                <button
                                    className={style.btn}
                                    onClick={handleChangePassword}
                                >

                                    { success ? success :  ' Зберегти' }
                                </button>

                                <button className={style['btn-gray']} onClick={closeModal}>
                                    Скасувати
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    );
}

PopupPersonal.propTypes = {
    name : PropTypes.string
};


PopupPersonal.defaultProps = {
    name : PropTypes.string
};


export default React.memo(PopupPersonal);
