import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const usePreviousLocation = () => {
    const history = useHistory();
    const location = useLocation();
    const [ previousLocation, setPreviousLocation ] = useState();

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (history.action === 'PUSH') {
                setPreviousLocation(location.pathname);
            }
        });

        return () => unlisten();
    }, [ location, history ]);

    return previousLocation;
};
