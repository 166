import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
// import history from '../../history';
import classNames from 'classnames';
import style from './CheckBox.module.scss';

function CheckBox({ online = false, name, id, type, code, otherValue }) {
    const checkbox = useRef();
    const [ checked, setChecked ] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!online) {
            const urlSearchParams = new URLSearchParams(location.search);
            const filters = urlSearchParams.getAll(`filters[${code}]`);

            const check = filters.some((filterId) => +filterId === +id);

            setChecked(check);
        } else setChecked(otherValue);
    }, [ location.search, code, id ]);

    const handleCheckboxChange = useCallback(event => {
        if (!online) {
            const { checked: targetChecked } = event.target;
            const urlSearchParams = new URLSearchParams(location.search);

            const filters = urlSearchParams.getAll(`filters[${code}]`);

            if (targetChecked) {
                filters.push(id);
            } else {
                const index = filters.findIndex((filterId) => +filterId === +id);

                if (index !== -1) {
                    filters.splice(index, 1);
                }
            }

            urlSearchParams.delete(`filters[${code}]`);
            filters.forEach((filterId) => {
                urlSearchParams.append(`filters[${code}]`, filterId);
            });

            history.push({
                search : urlSearchParams.toString()
            });
        } else {
            setChecked(item => !item);
        }
        // eslint-disable-next-line more/no-duplicated-chains
    }, [ location.search ]);

    useEffect(() => {
        if (id === 'young') {
            setChecked(otherValue);
        }
    }, [ id, otherValue ]);

    return (
        <fieldset className={style.checkbox}>
            <input
                ref={checkbox}
                className={classNames(style.input, '--border')}
                name={name}
                data-parentcode={code}
                id={id}
                onChange={handleCheckboxChange}
                checked={checked}
                type={type ? type : 'checkbox'}
            />
            <label className={classNames(style.label, '--transparent')} htmlFor={id}>
                {name}
            </label>
        </fieldset>
    );
}

CheckBox.propTypes = {
    name       : PropTypes.string,
    id         : PropTypes.string,
    type       : PropTypes.string,
    code       : PropTypes.string || PropTypes.number,
    online     : PropTypes.bool,
    otherValue : PropTypes.bool
};

CheckBox.defaultProps = {
    name       : null,
    id         : null,
    type       : null,
    code       : null,
    online     : false,
    otherValue : false
};

export default CheckBox;
