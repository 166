import Base from './Base.js';

class AddInCart extends Base {
    list(queryParams) {
        return this.apiClient.post({
            requestURL : 'api/cart/add',
            payload    : queryParams
        });
    }
}

export default AddInCart;
