import ACTIONS from '../constants/actionsTypes';

const initialState = {
    city : {
        id : 1
    },
    district : {}
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_CURRENT_CITY:
            return {
                ...state,
                city : data
            };
        case ACTIONS.SET_DISTRICT:
            return {
                ...state,
                district : data
            };
        default:
            return state;
    }
};
