import ACTIONS from '../constants/actionsTypes';

const initialState = {
    slider       : [],
    banner       : [],
    group_banner : [],
    error        : null,
    isLoading    : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_HERO_BANNER_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_HERO_BANNER_SUCCESS:
            return {
                ...state,
                banner    : data,
                isLoading : false
            };
        case ACTIONS.GET_HERO_BANNER_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };

        case ACTIONS.GET_HERO_BANNER_SLIDER_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_HERO_BANNER_SLIDER_SUCCESS:
            return {
                ...state,
                slider    : data,
                isLoading : false
            };
        case ACTIONS.GET_HERO_BANNER_SLIDER_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };

        case ACTIONS.GET_HERO_BANNER_GROUP_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_HERO_BANNER_GROUP_SUCCESS:
            return {
                ...state,
                group_banner : data,
                isLoading    : false
            };
        case ACTIONS.GET_HERO_BANNER_GROUP_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };
        default:
            return state;
    }
};
