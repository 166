import React from 'react';

function CheckMarkIcon() {
    return (
        <svg
            style={{ minWidth: '1.563rem' }}
            width='25'
            height='25'
            viewBox='0 0 25 25'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M24.2848 3.41773C23.917 3.04932 23.3203 3.04869 22.9525 3.41617L11.6491 14.6897L7.57091 10.2604C7.21852 9.87784 6.62272 9.85303 6.23956 10.2054C5.8567 10.5578 5.83219 11.1539 6.18458 11.5367L10.9271 16.6872C11.1007 16.8759 11.3438 16.9856 11.6001 16.9909C11.607 16.9912 11.6136 16.9912 11.6202 16.9912C11.8693 16.9912 12.1089 16.8922 12.2854 16.7164L24.2829 4.7503C24.6516 4.38287 24.6523 3.78614 24.2848 3.41773Z' fill='#47CB44' />
            <path d='M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z' fill='#47CB44' />
        </svg>

    );
}

export default React.memo(CheckMarkIcon);
