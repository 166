import Base from './Base.js';

class Checkout extends Base {
    showcases(payload) {
        return this.apiClient.post({
            requestURL : 'api/checkout/showcases',
            payload
        });
    }
}

export default Checkout;
