import api                              from '../../apiSingleton';
import ACTIONS                          from '../constants/actionsTypes';

export function getPersonalAreaList(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_REQUEST
            });

            const productsList = await api.personalArea.list(params);

            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] getProductsList', err);
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function getPersonalAreaLastList(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_LAST_REQUEST
            });

            const productsList = await api.personalArea.last(params);

            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_LAST_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] PERSONAL_AREA_LAST', err);
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_LAST_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function getPersonalAreaInfoList(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_INFO_REQUEST
            });

            const productsList = await api.personalArea.info(params);

            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_INFO_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] GET_PERSONAL_AREA_INFO', err);
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_INFO_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function personalAreaChangePassword(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_CHANGE_PASSWORD_REQUEST
            });

            const productsList = await api.personalArea.changePassword(params);

            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_CHANGE_PASSWORD_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] CHANGE_PASSWORD', err);
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_CHANGE_PASSWORD_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function personalAreaUpdateInfo(id, params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_UPDATE_INFO_REQUEST
            });

            const productsList = await api.personalArea.updateInfo(id, params);

            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_UPDATE_INFO_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] UPDATE_INFO', err);
            dispatch({
                type : ACTIONS.GET_PERSONAL_AREA_UPDATE_INFO_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

