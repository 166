import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import classNames      from 'classnames';
import PropTypes       from 'prop-types';
import ClosePopupIcon  from '../../assets/icon/ClosePopupIcon';
import ThumbtackIcon   from '../../assets/icon/ThumbtackIcon';
import img             from '../../assets/images/product.jpg';
import Button          from '../Button';
import { cartListSelector } from '../../store/selectors/Cart';
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/localStarage';
import { getCart }     from '../../store/actions/Cart';
import history         from '../../history';
import style           from './PopupCart.module.scss';

function PopupCart({ event, name, position }) {
    const { items = [] }  = useSelector(cartListSelector);
    const dispatch = useDispatch();
    const [ fixed, setFixed ] = useState(false);

    const allPrice = useMemo(() => {
        return items.reduce((total, obj) => total + (obj.price * obj.quantity), 0);
    }, [ items ]);

    // eslint-disable-next-line no-shadow
    const handleCart = useCallback((event, data) => {
        const { target } = event;

        const { productId } = data;

        if (target.closest('button')) {
            const cart = getFromLocalStorage('cart');

            const updatedCart = cart.map(product => {
                if (product.productId === data.productId) return;

                return product;
            }).filter(_ => _);

            saveToLocalStorage('cart', updatedCart);
            dispatch(getCart());
        } else {
            history.push(`/product/${productId}`);
        }
    }, []);

    const handleClick = useCallback(async () => {
        const element = name.current;

        await setFixed(prev => {
            if (prev) {
                element.classList.remove(style.fixed);
            }

            return !prev;
        });
    }, [ fixed ]);

    const handleScroll = useCallback(() => {
        const element = name.current;

        if (!fixed) {
            element.classList.remove(style.fixed);

            return;
        }

        const { top } = position;
        // eslint-disable-next-line react/prop-types

        // const scrollPosition = window.scrollY - 57;


        const scrollPosition = window.scrollY - (window.innerWidth > 768 ? 57 : 87);


        if (scrollPosition > top) {
            element.classList.add(style.fixed); // Добавляем класс при прокрутке более 200px
        } else {
            element.classList.remove(style.fixed); // Удаляем класс при прокрутке менее 200px
        }
    }, [ fixed ]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [ fixed ]);

    return (
        <section ref={name} className={classNames(style['popup-cart'])}>
            <div className={style['popup-cart__title']}>
                <h4>
                    У кошику {items.length} товарів
                </h4>

                <div>
                    <button
                        className={classNames(style['popup-cart__svg'], { [style.border]: fixed })}
                        onClick={handleClick}
                    >
                        <ThumbtackIcon />
                    </button>
                    <button onClick={event}>
                        <ClosePopupIcon />
                    </button>
                </div>
            </div>

            <div className={style['popup-cart__card']}>
                {
                    items.length > 0 && items?.map(item => (
                        // eslint-disable-next-line react/jsx-no-bind
                        <div key={item?.productId} onClick = {(e) => handleCart(e, item)}>
                            <img
                                data-src={item.image ? item.image : img}
                                height={61}
                                width={61}
                                className={'lazyload'}
                                alt={'img'}
                            />

                            <div>
                                <h4>
                                    { item?.name || 'Test name' }
                                </h4>

                                <p>
                                    { item?.quantity.toFixed(2) } шт. ×  { item?.price.toFixed(2) } грн =  { (item?.quantity * item?.price).toFixed(2) } грн
                                </p>

                                {/* <p>*/}
                                {/*    { item?.count } шт. × { item?.discounts?.length > 0 ? item?.discounts[0]?.price : item?.price[0]?.price }*/}
                                {/*    грн = { `${(item?.discounts?.length > 0 ? item?.discounts[0]?.price : item?.price[0]?.price) * item?.count  }` }  грн*/}
                                {/* </p>*/}
                            </div>


                            <button>
                                <ClosePopupIcon />
                            </button>
                        </div>
                    ))
                }

                {   !items.length > 0 &&
                    <h4
                        style={{
                        padding   : '0  16px',
                        marginTop : '0.5625rem'
                    }}>Кошик пустий</h4>
                }
            </div>

            <div className={style['popup-cart__button']}>
                <div>
                    <span>Разом до сплати:</span>
                    <span>{allPrice.toFixed(2)} грн</span>
                </div>

                <Button
                    clsTitle={!items.length > 0 && style.disable}
                    name={'Оформити замовлення'}
                    event={event}
                    link={'/checkout'} />
            </div>
        </section>
    );
}

PopupCart.propTypes = {
    event    : PropTypes.func,
    name     : PropTypes.element,
    position : PropTypes.object
};

export default React.memo(PopupCart);
