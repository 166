import React from 'react';

function ThumbtackIcon() {
    return (
        <svg
            width='26' height='26' viewBox='0 0 26 26'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M25.6624 9.5683L16.6983 0.604179C16.2909 0.196719 15.6797 0.196719 15.2722 0.604179L12.0125 3.86386C11.5032 4.37318 11.7069 4.98437 12.0125 5.28997L12.7256 6.00302L9.66963 9.05897C8.14165 8.75338 3.96519 8.04032 1.72416 10.2814C1.3167 10.6888 1.3167 11.3 1.72416 11.7075L7.53046 17.5138L1.11297 23.9313C0.705508 24.3387 0.705508 24.9499 1.11297 25.3574C1.52043 25.7648 2.23348 25.663 2.53908 25.3574L8.95657 18.9399L14.7629 24.7462C15.3741 25.2555 15.9853 25.0518 16.189 24.7462C18.43 22.5052 17.717 18.3287 17.4114 16.8007L20.4673 13.7448L21.1804 14.4578C21.5878 14.8653 22.199 14.8653 22.6065 14.4578L25.8662 11.1981C26.0699 10.5869 26.0699 9.97576 25.6624 9.5683V9.5683ZM21.6897 12.1149L20.9766 11.4019C20.5692 10.9944 19.958 10.9944 19.5505 11.4019L15.3741 15.6802C15.0685 15.9858 14.9666 16.2914 15.0685 16.6988C15.3741 17.8194 15.8834 20.5697 15.0685 22.4033L3.86332 11.1981C5.59503 10.3832 8.44725 10.8925 9.56776 11.1981C9.87336 11.3 10.2808 11.1981 10.5864 10.8925L14.7629 6.71608C15.3741 6.10489 15.0685 5.59556 14.7629 5.28997L14.0498 4.57691L15.9853 2.74334L23.5233 10.2814L21.6897 12.1149Z' fill='black' />
        </svg>
    );
}

export default React.memo(ThumbtackIcon);
