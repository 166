import React, { useState }    from 'react';
import PropTypes                         from 'prop-types';
import { ModalContext }                  from './ModalContext';

export function ModalProvider({ children }) {
    const [ activeModal, setActiveModal ] = useState(null);

    function toggleModal({ id, props = {} }) {
        setActiveModal({ id, props });
    }

    function dismiss() {
        setActiveModal(null);
    }

    const context = {
        activeModal,
        toggleModal,
        dismiss
    };

    return (
        <ModalContext.Provider value={context}>
            {children}
        </ModalContext.Provider>
    );
}

ModalProvider.propTypes = {
    children : PropTypes.oneOfType([ PropTypes.node, PropTypes.array ])
};

ModalProvider.defaultProps = {
    children : null
};

