import { SERVER_CODES, ERROR_CODES } from '../constants';

export async function processError(res) {
    const result = res.json ? await res.json() : res;

    if (res.status >= SERVER_CODES.SERVER_ERROR) {
        const errorObj = {
            type   : ERROR_CODES.SERVER_ERROR,
            status : res.status,
            ...result
        };

        throw errorObj;
    }

    if (res.status === SERVER_CODES.VALIDATION_ERROR) {
        const errorObj = {
            errors : result,
            code   : ERROR_CODES.VALIDATION_ERROR
        };

        throw errorObj;
    }

    if (res.status === SERVER_CODES.NOT_FOUND) {
        const errorObj = {
            code   : ERROR_CODES.NOT_FOUND,
            status : res.status,
            ...result
        };

        throw errorObj;
    }

    if (res.status === SERVER_CODES.UNAUTHORIZED) {
        const errorObj = {
            errors : result,
            code   : ERROR_CODES.UNAUTHORIZED
        };

        throw errorObj;
    }

    const errorObj = {
        code   : ERROR_CODES.UNKNOWN_ERROR,
        status : res.status,
        ...result
    };

    throw errorObj;
}
