import { configureStore } from '@reduxjs/toolkit';
import reducers           from './reducers';

function loadState() {
    try {
        const serializedState = localStorage.getItem('checkout');

        if (!serializedState) {
            return undefined;
        }


        const parsedState = JSON.parse(serializedState);

        return { checkout: parsedState };
    } catch (err) {
        console.log(err);

        return undefined;
    }
}

const ititialState = loadState();

const store = configureStore({
    reducer        : reducers,
    devTools       : process.env.NODE_ENV !== 'production',
    preloadedState : ititialState,
    middleware     : (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck    : false,
        serializableCheck : false
    })
});

export { store };
