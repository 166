import api     from '../../apiSingleton';
import ACTIONS from '../constants/actionsTypes';

export function addInCart(params = {}) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_ADD_IN_CART_REQUEST
            });

            const storedJson = await api.addInCart.list(params);

            const session = localStorage.getItem('sessionId');

            if (!session) localStorage.setItem('sessionId', storedJson);

            dispatch({
                type : ACTIONS.GET_ADD_IN_CART_SUCCESS,
                data : !session ? storedJson : session
            });
        } catch (err) {
            console.log('[ERROR] getADD_IN_CART', err);
            dispatch({
                type : ACTIONS.GET_ADD_IN_CART_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
