import api     from '../../apiSingleton';
import ACTIONS from '../constants/actionsTypes';

export function getOffer(id, type = 'city') {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_OFFER_REQUEST
            });

            const offer = await api.publicOffer.show(id, type);

            if (offer?.error) {
                return dispatch({
                    type : ACTIONS.GET_OFFER_ERROR,
                    data : offer.error
                });
            }

            dispatch({
                type : ACTIONS.GET_OFFER_SUCCESS,
                data : offer.publicOffer
            });
        } catch (err) {
            console.log('[ERROR] getOffer', err);
            dispatch({
                type : ACTIONS.GET_CART_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
