/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import React,
{
    useCallback,
    useEffect, useRef,
    useState
} from 'react';
import { Link }      from 'react-router-dom';
import
    {
        CSSTransition
    }                 from 'react-transition-group';
import
{
    useDispatch, useSelector
} from 'react-redux';
import { useModal }  from '../../utils/useModal';
import Logo          from '../../assets/images/logo.png';
import Pharmacy      from '../../assets/images/pharmacy.png';
import Gaevsky       from '../../assets/images/gaevskogo.png';
import accountPeople from '../../assets/images/anonim.png';
import MarkerIcon    from '../../assets/icon/MarkerIcon';
import EyeIcon       from '../../assets/icon/EyeIcon';
import History       from '../../history';
import HeartIcon     from '../../assets/icon/HeartIcon';
import ProfileIcon   from '../../assets/icon/ProfileIcon';
import Search        from '../search';
import ArrowBtn      from '../../assets/icon/ArrowBtn';
import useMediaQuery from '../../hooks/useMediaQuery';
import { MODALS }    from '../../constants';
import LocationPopup from '../LocationPopup';
import { getCart }   from '../../store/actions/Cart';
import { citiesListSelector } from '../../store/selectors/Cities';
import { getCities } from '../../store/actions/Cities';
import { citySelector } from '../../store/selectors/City';
import { getCurrentCity } from '../../store/actions/City';
import { getStatusVisuallyImpaired } from '../../store/actions/VisuallyImpaired';
import { statusVisuallyImpairedSelector } from '../../store/selectors/VisuallyImpaired';
import { getOffer } from '../../store/actions/Offer';
import { usePreviousLocation } from '../../hooks/usePreviousLocation';
import { checkoutSelector } from '../../store/selectors/Checkout';
import style         from './header.module.scss';

function Header() {
    const isDesktop = useMediaQuery('(min-width: 1100px)');
    const isTablet = useMediaQuery('(min-width: 768px)');
    const [ location, setLocation ] = useState(false);
    const search = useRef(null);
    const [ cityActive, setCityActive ] = useState([]);
    const dispatch = useDispatch();
    const { toggleModal } = useModal();

    const cities = useSelector(citiesListSelector);
    const status = useSelector(statusVisuallyImpairedSelector);
    const { city } = useSelector(citySelector);

    const { merchantId, method } = useSelector(checkoutSelector);

    const prevPathname = usePreviousLocation();

    const checkToken = document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1];

    const toggleCatalog = useCallback(() => {
        toggleModal({ id: MODALS.CATALOG_MODAL });
    }, []);

    const toggleOfferPopup = useCallback(() => {
        toggleModal({ id: MODALS.POPUP_OFFER });
    }, []);

    useEffect(() => {
        console.log(city);
        if (Object.keys(city).length) setCityActive([ city ]);
        fetchOffer();
    }, [ city ]);

    const fetchOffer = useCallback(async () => {
        if (city?.id) {
            await dispatch(getOffer(city?.id));
        }
    }, [ city ]);

    const fetchNewOffer = useCallback(async () => {
        if (prevPathname === '/checkout' && method === 'pickup-from-our-pharmacies' && merchantId) {
            await dispatch(getOffer(city?.id));
        }
    }, [ prevPathname, method, merchantId, city ]);

    useEffect(() => {
        fetchNewOffer();
    }, [ fetchNewOffer ]);

    const lsCity = localStorage.getItem('city');

    const handleVisuallyImpaired = useCallback(async () => {
        document.querySelector('html').className = '';
        await dispatch(getStatusVisuallyImpaired(!status));
    }, [ status ]);

    const fetchDataCities = useCallback(async () => {
        await dispatch(getCities());
    }, [ cities ]);

    useEffect(() => {
        if (cities.length > 0 && !cityActive.length > 0) {
            const arr = cities.filter(item => item?.nameUa === 'Одеса');

            setCityActive(() => arr);

            dispatch(getCurrentCity({ ...arr[0] }));
        }
    }, [ cities ]);

    useEffect(() => {
        if (!lsCity && !cities.length > 0) {
            fetchDataCities();
        } else if (cities.length > 0 && !lsCity) {
            const arr = cities.filter(item => item?.nameUa === 'Одеса');

            setCityActive(() => arr);
            dispatch(getCurrentCity({ ...arr[0] }));
        } else if (!cities.length > 0 && lsCity) {
            setCityActive([ JSON.parse(lsCity) ]);
            dispatch(getCurrentCity(JSON.parse(lsCity)));
        }
    }, []);

    const checkLogin = useCallback(async (e) => {
        e.preventDefault();

        if (checkToken) {
            History.push('/personal-area');
        } else {
            toggleModal({ id: MODALS.POPUP_LOGIN });
        }
    }, [ checkToken ]);

    const locationPopupHandle = useCallback((state) => {
        if (typeof state === 'object') {
            setLocation(true);
        } else {
            setLocation(false);
        }
    }, []);

    const handleSmall = useCallback(() => {
        document.querySelector('html').className = '';
        document.querySelector('html').classList.add('small');
    }, []);

    const handleBig = useCallback(() => {
        document.querySelector('html').className = '';
        document.querySelector('html').classList.add('big');
    }, []);

    const handleBase = useCallback(() => {
        document.querySelector('html').className = '';
        document.querySelector('html').classList.add('base');
    }, []);

    const fetchData = useCallback(async () => {
        await dispatch(getCart());
    }, []);

    useEffect(() => {
        const storedJson = localStorage.getItem('sessionId');

        if (storedJson) fetchData();
    }, []);

    function Dropdown() {
        return (
            <nav className={style['dropdown-children']}>
                <div>
                    <ul>
                        <li>
                            <Link to='/history'>
                                Наша історія
                            </Link>
                        </li>

                        <li>
                            <button
                                className = {style.offerButton}
                                onClick={toggleOfferPopup}
                            >
                                Публічна оферта
                            </button>
                        </li>

                        <li>
                            <Link to='/about-us' >
                                Про нас
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }

    return (
        <header className={style.header}>
            <div className={style.container}>
                <div className={`header-wrapper ${  style['header-wrapper']}`}>
                    <div className={style['logo-partner']}>
                        <Link className={style.logo} to={'/'} >
                            <img
                                src={Logo} className={style['w-full']}
                                alt={'logo'} />
                        </Link>

                        <div className={style['img-partner-wrapper']}>
                            <img
                                className={style['img-partner']}
                                src={Gaevsky}
                                alt='' />
                            <img
                                className={style['img-partner']}
                                src={Pharmacy}
                                alt='' />
                        </div>
                    </div>

                    {
                        isTablet &&

                        <div className={style['container-profile']}>
                            <nav className={style.nav}>
                                <ul className={style['list-item']}>
                                    <li className={style['list-item__children']}>
                                        <a href='/'>
                                            Покупцям
                                        </a>
                                        <ArrowBtn />

                                        <Dropdown />
                                    </li>

                                    <li>
                                        <Link to='/contact'>
                                            Контакти
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to='/delivery-and-payment' >
                                            Оплата і доставка
                                        </Link>
                                    </li>
                                </ul>
                            </nav>

                            <div className={style['btn-wrapper']}>
                                {
                                    !status &&
                                    <button className={style['btn-blue']} onClick={handleVisuallyImpaired}>
                                        <EyeIcon />
                                        <span>
                                            Для слабозорих
                                        </span>
                                    </button>
                                }

                                {
                                    status &&

                                    <button className={style['btn-blue']}>
                                        <button onClick={handleVisuallyImpaired}>
                                            <EyeIcon />
                                        </button>
                                        <>
                                            <button
                                                data-attr='small' className={style.btn_a} style={{ marginLeft: 5 }}
                                                onClick={handleSmall}>
                                                A-
                                            </button>

                                            <button data-attr='base' className={style.btn_a} onClick={handleBase}>
                                                A0
                                            </button>

                                            <button data-attr='big' className={style.btn_a} onClick={handleBig}>
                                                A+
                                            </button>
                                        </>
                                    </button>
                                }

                                <button className={style['btn-blue']} onClick={locationPopupHandle}>
                                    <MarkerIcon />
                                    <span>
                                        { cityActive[0]?.nameUa || 'Одеса' }
                                    </span>
                                </button>

                                <CSSTransition
                                    timeout={300}
                                    in={location}
                                    unmountOnExit
                                    classNames='item'
                                    mode={'out-in'}
                                >
                                    <LocationPopup event={locationPopupHandle} />
                                </CSSTransition>
                            </div>

                            <div className={style['profile-wrapper']}>
                                <button>
                                    <HeartIcon />
                                </button>

                                <a
                                    href={'#'}
                                    onClick={checkLogin}
                                    className={style.icon} >
                                    {
                                        checkToken ?
                                            <img   src={accountPeople}  alt={''} />
                                            : <ProfileIcon />
                                    }
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Search
                isTablet={isTablet}
                toggleCatalog={toggleCatalog}
                isDesktop={isDesktop}
                checkLogin={checkLogin}
                handleVisuallyImpaired={handleVisuallyImpaired}
                searchRef={search}
                city={cityActive[0]?.nameUa || 'Одеса'}
            />
        </header>
    );
}

export default Header;
