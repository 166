/* eslint-disable more/no-hardcoded-configuration-data */
import React, {
    useState,
    useEffect,
    useCallback
}                          from 'react';
import PropTypes           from 'prop-types';
import {
    getFromLocalStorage,
    saveToLocalStorage
}                          from '../utils/localStarage';
import { LS_KEYS }         from '../constants';
import { CheckoutStateContext } from './CheckoutStateContext';

export function CheckoutStateProvider({ children }) {
    const [ address, setAddress ]             = useState('');
    const [ firstName, setFirstName ]         = useState('');
    const [ lastName, setLastName ]           = useState('');
    const [ surname, setSurname ]             = useState('');
    const [ phoneNumber, setPhoneNumber ]     = useState('');
    const [ flat, setFlat ]                   = useState('');
    const [ floor, setFloor ]                 = useState('');
    const [ entrance, setEntrance ]           = useState('');
    const [ clientComment, setClientComment ] = useState('');
    const [ paymentType, setPaymentType ]     = useState('on_delivery');
    const [ method, setMethod ]               = useState('courier');
    const [ isEditAddress, setIsEditAddress ] = useState(false);

    useEffect(() => {
        const lsAddress       = getFromLocalStorage(LS_KEYS.ADDRESS);
        const lsFirstName     = getFromLocalStorage(LS_KEYS.FIRST_NAME);
        const lsLastName      = getFromLocalStorage(LS_KEYS.LAST_NAME);
        const lsSurname       = getFromLocalStorage(LS_KEYS.SURNAME);
        const lsPhoneNumber   = getFromLocalStorage(LS_KEYS.PHONE_NUMBER);
        const lsFlat          = getFromLocalStorage(LS_KEYS.FLAT);
        const lsFloor         = getFromLocalStorage(LS_KEYS.FLOOR);
        const lsEntrance      = getFromLocalStorage(LS_KEYS.ENTRANCE);
        const lsClientComment = getFromLocalStorage(LS_KEYS.CLIENT_COMMENT);
        const lsPaymentType   = getFromLocalStorage(LS_KEYS.PAYMENT_TYPE);
        const lsMethod        = getFromLocalStorage(LS_KEYS.METHOD);

        if (lsAddress) setAddress(lsAddress);
        if (lsFirstName) setFirstName(lsFirstName);
        if (lsLastName) setLastName(lsLastName);
        if (lsSurname) setSurname(lsSurname);
        if (lsPhoneNumber) setPhoneNumber(lsPhoneNumber);
        if (lsFlat) setFlat(lsFlat);
        if (lsFloor) setFloor(lsFloor);
        if (lsEntrance) setEntrance(lsEntrance);
        if (lsClientComment) setClientComment(lsClientComment);
        if (lsPaymentType) setPaymentType(lsPaymentType);
        if (lsMethod) setMethod(lsMethod);
    }, []);

    const changeAddress = useCallback((newAddress) => {
        setAddress(newAddress);
        saveToLocalStorage(LS_KEYS.ADDRESS, newAddress);
    }, []);

    const changeFirstName = useCallback((newFirstName) => {
        setFirstName(newFirstName);
        saveToLocalStorage(LS_KEYS.FIRST_NAME, newFirstName);
    }, []);

    const changeLastName = useCallback((newLastName) => {
        setLastName(newLastName);
        saveToLocalStorage(LS_KEYS.LAST_NAME, newLastName);
    }, []);

    const changeSurname = useCallback((newSurname) => {
        setSurname(newSurname);
        saveToLocalStorage(LS_KEYS.SURNAME, newSurname);
    }, []);

    const changePhoneNumber = useCallback((newPhoneNumber) => {
        setPhoneNumber(newPhoneNumber);
        saveToLocalStorage(LS_KEYS.PHONE_NUMBER, newPhoneNumber);
    }, []);

    const changeFlat = useCallback((newFlat) => {
        setFlat(newFlat);
        saveToLocalStorage(LS_KEYS.FLAT, newFlat);
    }, []);

    const changeFloor = useCallback((newFloor) => {
        setFloor(newFloor);
        saveToLocalStorage(LS_KEYS.FLOOR, newFloor);
    }, []);

    const changeEntrance = useCallback((newEntrance) => {
        setEntrance(newEntrance);
        saveToLocalStorage(LS_KEYS.ENTRANCE, newEntrance);
    }, []);

    const changeClientComment = useCallback((newClientComment) => {
        setClientComment(newClientComment);
        saveToLocalStorage(LS_KEYS.CLIENT_COMMENT, newClientComment);
    }, []);

    const changePaymentType = useCallback((newPaymentType) => {
        setPaymentType(newPaymentType);
        saveToLocalStorage(LS_KEYS.PAYMENT_TYPE, newPaymentType);
    }, []);

    const changeMethod = useCallback((newMethod) => {
        setMethod(newMethod);
        saveToLocalStorage(LS_KEYS.METHOD, newMethod);
    }, []);

    const clearChekoutState = useCallback(() => {
        changeAddress('');
        changeFirstName('');
        changeLastName('');
        changeSurname('');
        changePhoneNumber('');
        changeFlat('');
        changeFloor('');
        changeEntrance('');
        changeClientComment('');
        changePaymentType('on_delivery');
        changeMethod('courier');
        saveToLocalStorage('cart', []);
    }, []);

    const context = {
        address,
        firstName,
        lastName,
        surname,
        phoneNumber,
        flat,
        floor,
        entrance,
        clientComment,
        paymentType,
        method,
        changeAddress,
        changeFirstName,
        changeLastName,
        changeSurname,
        changePhoneNumber,
        changeFlat,
        changeFloor,
        changeEntrance,
        changeClientComment,
        changePaymentType,
        changeMethod,
        clearChekoutState,
        isEditAddress,
        setIsEditAddress
    };

    return (
        <CheckoutStateContext.Provider value={context}>
            {children}
        </CheckoutStateContext.Provider>
    );
}

CheckoutStateProvider.propTypes = {
    children : PropTypes.oneOfType([ PropTypes.node, PropTypes.array ])
};

CheckoutStateProvider.defaultProps = {
    children : null
};

