import React from 'react';

function ClosePopupIcon() {
    return (
        <svg
            width='26' height='26' viewBox='0 0 26 26'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L25 25' stroke='black' strokeWidth='2' />
            <path d='M25 1L1 25' stroke='black' strokeWidth='2' />
        </svg>

    );
}

export default React.memo(ClosePopupIcon);
