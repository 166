import Base from './Base.js';

class Products extends Base {
    list(id, queryParams) {
        return this.apiClient.get({
            requestURL : `api/product/${id}`,
            queryParams
        });
    }

    search(queryParams, showcaseId = 1) {
        return this.apiClient.get({
            requestURL : `api/product/filter/showcase/${showcaseId}`,
            queryParams
        });
    }

    analogues(id, showcaseId = 1) {
        return this.apiClient.get({
            requestURL : `api/product/same/${showcaseId}/${id}`
        });
    }

    availability(queryParams, id, showcaseId = 1) {
        return this.apiClient.get({
            requestURL : `api/product/${id}/availability/${showcaseId}`,
            queryParams
        });
    }
}

export default Products;
