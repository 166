import Base from './Base.js';

class UploadImgFile extends Base {
    upload(file) {
        return this.apiClient.post({
            requestURL : 'api/file/upload',
            payload    : file
        });
    }
}

export default UploadImgFile;
