export const productsListSelector = store => store.products.list;
export const isLoadingProductsSelector = store => store.products.isLoading;
export const errorProductsList = store => store.products.error;

export const productsAnalogListSelector = store => store.products.analogList;
export const isLoadingProductsAnalogSelector = store => store.products.isLoadingAnalogues;
export const errorProductsAnalogList = store => store.products.analoguesError;

export const searchProductsListSelector = store => store.products.searchList;
export const isLoadingSearchProductsSelector = store => store.products.isLoadingSearch;
export const searchErrorProductsListSelector = store => store.products.searchError;
export const searchStringSelector = store => store.products.searchString;
export const searchProductsListPageSelector = store => store.products.page;
export const searchProductsListPagesSelector = store => store.products.pages;

export const productsAvailabilityListSelector = store => store.products.availabilityList;
export const isLoadingProductsAvailabilitySelector = store => store.products.isLoadingAvailability;
export const errorProductsAvailabilityList = store => store.products.availabilityError;
