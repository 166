import React from 'react';

function TwitIcon() {
    return (
        <svg
            style={{ minWidth: '0.938rem' }}
            width='15' height='12' viewBox='0 0 15 12'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M14.4336 1.50109C13.9108 1.73273 13.3493 1.88924 12.7589 1.95998C13.368 1.59549 13.8238 1.02184 14.041 0.346053C13.4687 0.685985 12.8424 0.92526 12.1892 1.05348C11.75 0.584489 11.1682 0.273636 10.5342 0.169181C9.90017 0.0647249 9.24941 0.17251 8.68293 0.475801C8.11645 0.779092 7.66596 1.26092 7.40138 1.84648C7.1368 2.43204 7.07295 3.08857 7.21973 3.71414C6.06011 3.65591 4.92569 3.35451 3.8901 2.82948C2.85451 2.30446 1.94088 1.56755 1.20852 0.666584C0.9581 1.09855 0.814112 1.59938 0.814112 2.13276C0.813833 2.61293 0.932078 3.08575 1.15836 3.50925C1.38464 3.93276 1.71195 4.29387 2.11126 4.56054C1.64817 4.5458 1.19529 4.42067 0.790323 4.19556V4.23312C0.790276 4.90658 1.02323 5.55931 1.44965 6.08056C1.87608 6.60181 2.46971 6.95947 3.12983 7.09286C2.70023 7.20913 2.24983 7.22625 1.81264 7.14295C1.99889 7.72241 2.36167 8.22914 2.85022 8.59218C3.33876 8.95521 3.9286 9.15639 4.53716 9.16755C3.50409 9.97853 2.22826 10.4184 0.914904 10.4165C0.682256 10.4166 0.449806 10.403 0.21875 10.3758C1.55188 11.233 3.10374 11.6879 4.68866 11.6861C10.0538 11.6861 12.9868 7.24248 12.9868 3.3886C12.9868 3.26339 12.9837 3.13693 12.978 3.01172C13.5485 2.59915 14.041 2.08825 14.4323 1.50297L14.4336 1.50109Z' fill='#A7A7A7' />
        </svg>
    );
}

export default React.memo(TwitIcon);
