import React, { useCallback, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import { useModal } from '../../utils/useModal';
import logo from '../../assets/images/gaevskogo.png';
import bookingImg from '../../assets/images/bookingImg.png';
import CheckMarkIcon from '../../assets/icon/CheckMarkIcon';
import style from './PopupBooking.module.scss';

function PopupBooking() {
    const popupBody = useRef(null);
    const popup = useRef();
    const firstUpdate = useRef(true);
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);

        return () => document.removeEventListener('click', closeModal);
    }, [ popupBody?.current ]);

    useEffect(() => {
        if (initPopup) {
            disableBodyScroll(popupBody?.current);
        }
    }, [ initPopup ]);

    const closeModal = useCallback((e) => {
        // if (!popupBody?.current || firstUpdate.current) {
        //     firstUpdate.current = false;
        //
        //     return;
        // }
        if (!popupBody?.current.contains(e.target)) {
            enableBodyScroll(popupBody?.current);
            dismiss();
        }
    }, [ popupBody, firstUpdate ]);

    const closeBtn = useCallback(() => {
        enableBodyScroll(popupBody?.current);
        dismiss();
    }, [ popupBody, firstUpdate ]);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <section ref={popup} className={style['popup-booking']}>
                    <div ref={popupBody} className={style['popup-booking__inner']}>
                        <button className={style['popup-booking__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>

                        <div className={style['popup-booking__top']}>
                            <img
                                data-src={logo} className={'lazyload'}
                                alt={''} />
                            <div>
                                <h4>
                                    пр. Академіка Глушка, 14, Одесса
                                </h4>
                                <p>
                                    пн-пт: 08:00-21:00
                                    <a href='/'>
                                        097 881 65 76
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className={style['popup-booking__center']}>
                            <img
                                data-src={bookingImg} alt={'test'}
                                className={'lazyload'} />
                            <div className={style['popup-booking__wrapper']}>
                                <div className={style['popup-booking__title']}>
                                    <p>
                                        <CheckMarkIcon />
                                        В наявності: <span> 2 </span>
                                    </p>
                                    <h2>
                                        Амброксол 30 сироп 30 мг/5 мл флакон полімерний 100 мл
                                    </h2>
                                </div>
                                <h3 className={style.price}>
                                    271.2 грн
                                </h3>
                                {/* <span className={style.count}>*/}
                                {/*    Упаковка / 20 шт.*/}
                                {/* </span>*/}
                                <div className={style['popup-booking__btn']}>
                                    <div className={style['popup-booking__btn-wrapper']}>
                                        <h4>Упаковка</h4>
                                        <div className={style['popup-booking__button-inner']}>
                                            <button>
                                                -
                                            </button>
                                            0
                                            <button>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className={style['popup-booking__btn-wrapper']}>*/}
                                    {/*    <h4>Блістер</h4>*/}
                                    {/*    <div className={style['popup-booking__button-inner']}>*/}
                                    {/*        <button>*/}
                                    {/*            -*/}
                                    {/*        </button>*/}
                                    {/*        0*/}
                                    {/*        <button>*/}
                                    {/*            +*/}
                                    {/*        </button>*/}
                                    {/*    </div>*/}
                                    {/* </div>*/}
                                </div>

                                <div className={style['popup-booking__form']}>
                                    <label htmlFor='#'>
                                        Мобільний телефон

                                        <input type='text' />
                                    </label>

                                    <button>
                                        Забронювати
                                    </button>
                                </div>

                                <p className={style.description}>
                                    {/* eslint-disable-next-line max-len */}
                                    Якщо перевищена кількість товарів, що є «В наявності», відсутня частина замовлення буде доставлятися безкоштовно до аптеки протягом 3-х днів
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupBooking);
