import Base from './Base.js';

class Registration extends Base {
    createToken(payload) {
        return this.apiClient.post({
            requestURL : 'api/users',
            payload    : payload || {
                email         : 'admin@admin.com',
                name          : 'admin',
                phone         : '099999',
                plainPassword : '123456'
            }
        });
    }
}

export default Registration;
