import React from 'react';

function PercentIcon() {
    return (
        <svg
            style={{ minWidth: '1.25rem' }}
            width='20' height='20' viewBox='0 0 20 20'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M9.24456 1.06493C9.40375 0.898605 9.6695 0.898605 9.82868 1.06493L11.2212 2.51993C11.3295 2.6331 11.4934 2.67351 11.6419 2.62364L13.5511 1.98242C13.7693 1.90912 14.0046 2.03262 14.0683 2.25387L14.6251 4.18932C14.6684 4.33987 14.7948 4.45184 14.9495 4.47669L16.9379 4.79615C17.1652 4.83267 17.3162 5.05137 17.2697 5.27686L16.8633 7.24938C16.8317 7.40281 16.8916 7.5607 17.017 7.65458L18.6292 8.86152C18.8135 8.9995 18.8455 9.26331 18.6996 9.44138L17.4231 10.9991C17.3238 11.1202 17.3034 11.2879 17.3708 11.4293L18.2375 13.2472C18.3366 13.455 18.2423 13.7035 18.0304 13.7934L16.1762 14.5794C16.0319 14.6406 15.936 14.7795 15.93 14.9361L15.8525 16.9485C15.8437 17.1786 15.6448 17.3548 15.4153 17.3359L13.4082 17.1702C13.2521 17.1573 13.1026 17.2358 13.0245 17.3716L12.0207 19.1175C11.9059 19.3171 11.6479 19.3807 11.4535 19.2573L9.7533 18.1778C9.62105 18.0939 9.45219 18.0939 9.31994 18.1778L7.61972 19.2573C7.42536 19.3807 7.16734 19.3171 7.05258 19.1175L6.04877 17.3716C5.97069 17.2358 5.82117 17.1573 5.66505 17.1702L3.65793 17.3359C3.42848 17.3548 3.22957 17.1786 3.22071 16.9485L3.14327 14.9361C3.13724 14.7795 3.04132 14.6406 2.89709 14.5794L1.04287 13.7934C0.830902 13.7035 0.736666 13.455 0.835739 13.2472L1.7024 11.4293C1.76981 11.2879 1.74946 11.1202 1.65016 10.9991L0.373626 9.44138C0.227698 9.26331 0.25973 8.9995 0.444033 8.86152L2.05626 7.65458C2.18167 7.5607 2.24155 7.40281 2.20993 7.24938L1.80352 5.27686C1.75706 5.05137 1.90802 4.83267 2.13533 4.79615L4.12378 4.47669C4.27845 4.45184 4.40485 4.33987 4.44816 4.18932L5.00497 2.25387C5.06862 2.03262 5.30393 1.90912 5.52217 1.98242L7.43132 2.62364C7.57982 2.67351 7.74377 2.6331 7.85209 2.51993L9.24456 1.06493Z'
                fill='#E46E9C' />
            <path
                d='M7.36576 9.76874C6.82263 9.76874 6.38178 9.60298 6.0432 9.27146C5.70463 8.93289 5.53534 8.5132 5.53534 8.01239C5.53534 7.50453 5.70463 7.08131 6.0432 6.74274C6.38178 6.40417 6.82263 6.23488 7.36576 6.23488C7.92299 6.23488 8.3709 6.40417 8.70947 6.74274C9.0551 7.08131 9.22791 7.50453 9.22791 8.01239C9.22791 8.5132 9.0551 8.93289 8.70947 9.27146C8.3709 9.60298 7.92299 9.76874 7.36576 9.76874ZM7.57736 13.3978H6.66745L11.1747 6.34068H12.0952L7.57736 13.3978ZM11.3546 13.5248C10.8114 13.5248 10.3706 13.359 10.032 13.0275C9.69345 12.6889 9.52416 12.2692 9.52416 11.7684C9.52416 11.2606 9.69345 10.8374 10.032 10.4988C10.3706 10.1602 10.8114 9.99093 11.3546 9.99093C11.9048 9.99093 12.3491 10.1602 12.6877 10.4988C13.0333 10.8374 13.2062 11.2606 13.2062 11.7684C13.2062 12.2692 13.0333 12.6889 12.6877 13.0275C12.3491 13.359 11.9048 13.5248 11.3546 13.5248ZM6.84731 8.59431C6.98133 8.72833 7.15415 8.79534 7.36576 8.79534C7.57736 8.79534 7.75018 8.72833 7.8842 8.59431C8.02527 8.45324 8.09581 8.25927 8.09581 8.01239C8.09581 7.75846 8.02527 7.56096 7.8842 7.41989C7.75018 7.27881 7.57736 7.20828 7.36576 7.20828C7.15415 7.20828 6.98133 7.27881 6.84731 7.41989C6.72035 7.56096 6.65687 7.75846 6.65687 8.01239C6.65687 8.25927 6.72035 8.45324 6.84731 8.59431ZM11.3546 12.5514C11.5732 12.5514 11.7496 12.4844 11.8836 12.3504C12.0176 12.2163 12.0846 12.0224 12.0846 11.7684C12.0846 11.5145 12.0141 11.317 11.873 11.1759C11.739 11.0349 11.5662 10.9643 11.3546 10.9643C11.143 10.9643 10.9702 11.0349 10.8361 11.1759C10.7092 11.317 10.6457 11.5145 10.6457 11.7684C10.6457 12.0224 10.7092 12.2163 10.8361 12.3504C10.9702 12.4844 11.143 12.5514 11.3546 12.5514Z'
                fill='white' />
        </svg>
    );
}

export default React.memo(PercentIcon);
