import { createSelector } from 'reselect';

export const cartListSelector = store => store.cart.list;
export const isLoadingCartSelector = store => store.cart.isLoading;
export const errorCartList = store => store.cart.error;
export const productIdsSelector = createSelector(
    cartListSelector,
    ({ items }) => {
        return items?.map(product => {
            return {
                id : product.productId
            };
    });
    }
);
