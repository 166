import React, { useCallback, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll }             from 'body-scroll-lock';
import { CSSTransition }                                   from 'react-transition-group';
import classNames                                          from 'classnames';
import { useModal }                                        from '../../utils/useModal';
import ClosePopupIcon                                      from '../../assets/icon/ClosePopupIcon';
import ImgPopup                                            from '../../assets/images/img-popup.png';
import viber                                               from '../../assets/images/viber.png';
import style                                               from './PopupConsultation.module.scss';

function PopupConsultation() {
    const popupBody = useRef(null);
    const popup = useRef();
    // const firstUpdate = useRef(true);
    const { dismiss } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);

        return () => document.removeEventListener('click', closeModal);
    }, [ popupBody?.current ]);

    useEffect(() => {
        if (initPopup) {
            disableBodyScroll(popupBody?.current);
        }
    }, [ initPopup ]);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current.contains(e.target)) {
            enableBodyScroll(popupBody?.current);
            dismiss();
        }
    }, [ popupBody, dismiss ]);

    // const handleViberChat = useCallback(() => {
    //     if (window.navigator && window.navigator.userAgent.match(/(iPad|iPhone|iPod|Android|Windows Phone)/i)) {
    //         // Проверка на наличие Viber на мобильных устройствах
    //         window.location.href = 'viber://pa?chatURI=farmaciaua';
    //     } else if (window.navigator && window.navigator.userAgent.match(/(Mac|Win)/i)) {
    //         // Проверка на наличие Viber на компьютерах
    //         window.open('https://chat.viber.com/farmaciaua', '_blank');
    //     } else {
    //         // Вариант по умолчанию (например, открыть веб-версию чата)
    //         window.open('https://chat.viber.com/farmaciaua', '_blank');
    //     }
    // }, []);

    const closeBtn = useCallback(() => {
        enableBodyScroll(popupBody?.current);
        dismiss();
    }, [ popupBody, dismiss ]);

    const handleKeyDown = useCallback((e) => {
        if (e.keyCode === 27) {
            enableBodyScroll(popupBody?.current);
            dismiss();
        }
    }, [ dismiss ]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ handleKeyDown ]);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <div ref={popup} className={classNames(style['popup-consultation'], '--transparent')}>
                    <div ref={popupBody} className={style['popup-consultation__inner']}>
                        <button className={style['popup-consultation__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>
                        <div>
                            <h2>Не треба ходити по аптеках! </h2>

                            <div className={style['popup-consultation__btn']}>
                                Консультуйся <span>Online</span>
                            </div>

                            <div className={style['popup-consultation__wrapper']}>
                                <img

                                    data-src={ImgPopup}
                                    className={'lazyload'}
                                    alt='test'
                                />

                                <div>
                                    <p>
                                        {/* eslint-disable-next-line max-len */}
                                        Консультуйся online з найкращими експертами - фармацевтами Аптеки Гаєвського. Ми надамо інформацію про ліки, їх аналоги, ціни та наявності в аптеках.
                                    </p>

                                    <a
                                        href={'viber://pa?chatURI=farmaciaua'}
                                        className={style.viber}
                                        // onClick={handleViberChat}
                                    >
                                        <img   src={viber} alt={''} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupConsultation);
