import ACTIONS from '../constants/actionsTypes';

export function getCatalogActiveCheckbox(params) {
    return dispatch => dispatch({
        type : ACTIONS.GET_CATALOG_ACTIVE_CHECKBOX,
        data : params
    });
}

export function removeCatalogActiveCheckbox(params) {
    return dispatch => dispatch({
        type : ACTIONS.REMOVE_CATALOG_ACTIVE_CHECKBOX,
        data : params
    });
}

export function mountCatalogActiveCheckbox(params) {
    return dispatch => dispatch({
        type : ACTIONS.MOUNT_CATALOG_ACTIVE_CHECKBOX,
        data : params
    });
}

export function changeSorting(data) {
    return (dispatch) => dispatch({
        type : ACTIONS.CHANGE_SORTING,
        data
    });
}

export function changePriceRange(data) {
    return (dispatch) => dispatch({
        type : ACTIONS.CHANGE_PRICE_RANGE,
        data
    });
}

export function removePriceRange(data) {
    return (dispatch) => dispatch({
        type : ACTIONS.REMOVE_CHANGE_PRICE_RANGE,
        data
    });
}
