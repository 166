import React, {
    useEffect,
    useRef,
    useCallback, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useModal }  from '../../utils/useModal';
import history from '../../history';
// import animation     from '../../assets/style/animation.module.scss';
import { getCatalogTree } from '../../store/actions/CatalogTree';
import {
    catalogTreeListSelector,
    isLoadingCatalogTreeSelector
} from '../../store/selectors/CatalogTree';
import { changeSorting, removeCatalogActiveCheckbox, removePriceRange } from '../../store/actions/CatalogActiveCheckbox';
import Spinner from '../Spinner';
import style         from './NavigationPopup.module.scss';
import Tablet        from './Tablet';
import Mobile        from './Mobile';

function NavigationPopup() {
    const popup = useRef();
    const dispatch = useDispatch();
    const wrapper = useRef();
    const popupBody = useRef(null);
    const isTablet = useMediaQuery('(min-width: 768px)');
    const { dismiss } = useModal();
    const catalogTreeList = useSelector(catalogTreeListSelector);
    const loading = useSelector(isLoadingCatalogTreeSelector);
    const [ init, setInit ] = useState(false);
    // const isLoadingCatalogTree = useSelector(isLoadingCatalogTreeSelector);
    // const errorCatalogTree = useSelector(errorCatalogTreeList);

    const fetchData = useCallback(async () => {
        await dispatch(getCatalogTree());
    }, []);

    useEffect(() => {
        setInit(true);
        document.addEventListener('click', closeModal);

        if (catalogTreeList.length) return;

        fetchData();

        return () => document.removeEventListener('click', closeModal);
    }, []);

    const handleLinkClick = useCallback((id) => {
        dispatch(removeCatalogActiveCheckbox());
        dispatch(removePriceRange());
        dispatch(changeSorting('price.desc'));
        history.push(`/catalog/${id}`);
    }, []);

    const closeModal = useCallback((e) => {
        if (!popupBody?.current) return;
        if (!popupBody?.current.contains(e.target)) dismiss();
    }, [ popupBody ]);

    return (
        <div ref={popup}>
            <div className={classNames(style['navigation-bg'], '--transparent')} />
            <CSSTransition
                timeout={300}
                in={init}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <div ref={wrapper} className={style['navigation-popup']}>
                    <div className={isTablet ? style.container : ''}>
                        <div
                            style={{
                                position : 'relative'
                            }}
                            ref={popupBody}
                        >
                            <CSSTransition
                                timeout={300}
                                in={loading}
                                unmountOnExit
                                classNames='item'
                                mode={'out-in'}
                            >
                                <Spinner />
                            </CSSTransition>

                            {
                                isTablet
                                    ? <Tablet
                                            list={catalogTreeList}
                                            onClickLink={handleLinkClick}
                                    />
                                    : <Mobile
                                            list={catalogTreeList}
                                            onClickLink={handleLinkClick}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default React.memo(NavigationPopup);
