import ACTIONS from '../constants/actionsTypes';

const initialState = {
    list                    : [],
    last                    : [],
    info                    : {},
    updateInfo              : null,
    updateInfoError         : null,
    updateInfoIsLoading     : false,
    changePassword          : null,
    changePasswordError     : null,
    listError               : null,
    lastError               : null,
    infoError               : null,
    listIsLoading           : false,
    lastIsLoading           : false,
    infoIsLoading           : false,
    changePasswordIsLoading : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_PERSONAL_AREA_REQUEST:
            return {
                ...state,
                listIsLoading : true
            };
        case ACTIONS.GET_PERSONAL_AREA_SUCCESS:
            return {
                ...state,
                list          : data,
                listIsLoading : false
            };
        case ACTIONS.GET_PERSONAL_AREA_ERROR:
            return {
                ...state,
                listError     : data,
                listIsLoading : false
            };

        case ACTIONS.GET_PERSONAL_AREA_LAST_REQUEST:
            return {
                ...state,
                lastIsLoading : true
            };
        case ACTIONS.GET_PERSONAL_AREA_LAST_SUCCESS:
            return {
                ...state,
                last          : data,
                lastIsLoading : false
            };
        case ACTIONS.GET_PERSONAL_AREA_LAST_ERROR:
            return {
                ...state,
                lastError     : data,
                lastIsLoading : false
            };

        case ACTIONS.GET_PERSONAL_AREA_INFO_REQUEST:
            return {
                ...state,
                lastIsLoading : true
            };
        case ACTIONS.GET_PERSONAL_AREA_INFO_SUCCESS:
            return {
                ...state,
                info          : data,
                infoIsLoading : false
            };
        case ACTIONS.GET_PERSONAL_AREA_INFO_ERROR:
            return {
                ...state,
                infoError     : data,
                infoIsLoading : false
            };


        case ACTIONS.GET_PERSONAL_AREA_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                lastIsLoading : true
            };
        case ACTIONS.GET_PERSONAL_AREA_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword          : data,
                changePasswordIsLoading : false
            };
        case ACTIONS.GET_PERSONAL_AREA_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordError     : data,
                changePasswordIsLoading : false
            };

        case ACTIONS.GET_PERSONAL_AREA_UPDATE_INFO_REQUEST:
            return {
                ...state,
                updateInfoIsLoading : true
            };
        case ACTIONS.GET_PERSONAL_AREA_UPDATE_INFO_SUCCESS:
            return {
                ...state,
                updateInfo          : data,
                updateInfoIsLoading : false
            };
        case ACTIONS.GET_PERSONAL_AREA_UPDATE_INFO_ERROR:
            return {
                ...state,
                updateInfoError     : data,
                updateInfoIsLoading : false
            };

        default:
            return state;
    }
};
