import React from 'react';

function ArrowBtn() {
    return (
        <svg
            style={{ minWidth: '0.875rem' }} width='14' height='7'
            viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1 0.5L7 6.5L13 0.5' stroke='black' strokeLinecap='round'
                strokeLinejoin='round' />
        </svg>
    );
}

export default React.memo(ArrowBtn);
