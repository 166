export const CATALOG_MODAL = 'CATALOG_MODAL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const ADD_NEW_LIST = 'ADD_NEW_LIST';
export const POPUP_DELIVERY  = 'POPUP_DELIVERY';
export const POPUP_DELIVERY_NEW  = 'POPUP_DELIVERY_NEW';
export const POPUP_STEP  = 'POPUP_STEP';
export const POPUP_CONSULTATION  = 'POPUP_CONSULTATION';
export const POPUP_BOOKING  = 'POPUP_BOOKING';
export const POPUP_LOGIN  = 'POPUP_LOGIN';
export const POPUP_REGISTER  = 'POPUP_REGISTER';
export const POPUP_DRAG = 'POPUP_DRAG';
export const POPUP_OFFER = 'POPUP_OFFER';
