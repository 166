import ACTIONS from '../constants/actionsTypes';

const initialState = {
    list      : [],
    error     : null,
    isLoading : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_CITIES_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_CITIES_SUCCESS:
            return {
                ...state,
                list      : data,
                isLoading : false
            };
        case ACTIONS.GET_CITIES_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };
        default:
            return state;
    }
};
