import Base from './Base.js';

class Payment extends Base {
    create(payload) {
        return this.apiClient.post({
            requestURL : 'api/payment/url',
            payload
        });
    }
}

export default Payment;
