import api                              from '../../apiSingleton';
import ACTIONS                          from '../constants/actionsTypes';
import { debounce }                     from '../../utils/debounce';
import { dumpProductsAvailabilityList } from '../../utils/dumpUtils';
// import { searchStringSelector }   from '../selectors/Products';
import { getSearchFilterCatalog }       from './FilterCatalog';

export function getProductsList(id, params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PRODUCTS_REQUEST
            });

            const productsList = await api.products.list(id, params);

            dispatch({
                type : ACTIONS.GET_PRODUCTS_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] getProductsList', err);
            dispatch({
                type : ACTIONS.GET_PRODUCTS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function getProductsAnalogList(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PRODUCTS_ANALOGS_REQUEST
            });

            const productsList = await api.products.analogues(params);

            console.log(productsList);

            dispatch({
                type : ACTIONS.GET_PRODUCTS_ANALOGS_SUCCESS,
                data : productsList
            });
        } catch (err) {
            console.log('[ERROR] getPRODUCTS_ANALOGSList', err);
            dispatch({
                type : ACTIONS.GET_PRODUCTS_ANALOGS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

const debouncedGetSearchProductsList = debounce(async (dispatch, getState, params) => {
    try {
        dispatch({
            type : ACTIONS.GET_SEARCH_PRODUCTS_REQUEST
        });

        // const searchString = searchStringSelector(getState());
        const searchProductsList = await api.products.search(params);

        // if (searchString !== params.searchText) {
            dispatch(getSearchFilterCatalog(searchProductsList.filters));
        // }

        dispatch({
            type : ACTIONS.GET_SEARCH_PRODUCTS_SUCCESS,
            data : {
                searchList   : searchProductsList.products,
                searchString : params.searchText,
                page         : searchProductsList.page,
                pages        : searchProductsList.pages
            }
        });
    } catch (err) {
        console.log('[ERROR] getSearchProductsList', err);
        dispatch({
            type : ACTIONS.GET_SEARCH_PRODUCTS_ERROR,
            data : err
        });
        throw (err);
    }
}, 300);

export function getSearchProductsList(params) {
    return async (dispatch, getState) => {
        const { pageSize } = getState().products;

        debouncedGetSearchProductsList(dispatch, getState, { ...params, pageSize, page: 1 });
    };
}

export function changeSearchPage(params) {
    return async (dispatch, getState) => {
        const { pageSize, page } = getState().products;

        try {
            dispatch({
                type : ACTIONS.GET_SEARCH_PRODUCTS_REQUEST
            });

            const searchProductsList = await api.products.search({ ...params, pageSize, page: +page + 1 });

            dispatch(getSearchFilterCatalog(searchProductsList.filters));

            dispatch({
                type : ACTIONS.SEARCH_PRODUCTS_LIST_CHANGE_PAGE,
                data : {
                    searchList : searchProductsList.products,
                    page       : searchProductsList.page
                }
            });
        } catch (err) {
            console.log('[ERROR] changeSearchPage', err);
            dispatch({
                type : ACTIONS.GET_SEARCH_PRODUCTS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function clearSearch() {
    return (dispatch) => {
        dispatch({ type: ACTIONS.CLEAR_SEARCH_LIST });
    };
}

export function getProductsAvailabilityList(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_PRODUCTS_AVAILABILITY_REQUEST
            });

            const { queryParams, id, showcaseId } = params;

            const availabilityList = await api.products.availability(queryParams, id, showcaseId);

            dispatch({
                type : ACTIONS.GET_PRODUCTS_AVAILABILITY_SUCCESS,
                data : dumpProductsAvailabilityList(availabilityList[0]?.availability)
            });
        } catch (err) {
            console.log('[ERROR] getProductsAvailabilityList', err);
            dispatch({
                type : ACTIONS.GET_PRODUCTS_AVAILABILITY_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
