import Base from './Base.js';

class PublicOffer extends Base {
    show(id, type) {
        return this.apiClient.get({
            requestURL : `api/public-offer/${type}/${id}`
        });
    }
}

export default PublicOffer;
