import React from 'react';

function CartIcon() {
    return (
        <svg
            style={{ minWidth: '1.5rem' }}
            width='24' height='22' viewBox='0 0 24 22'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M19.0023 15.9802C17.3522 15.9802 16.0098 17.3227 16.0098 18.9727C16.0098 20.6228 17.3522 21.9653 19.0023 21.9653C20.6523 21.9653 21.9948 20.6228 21.9948 18.9727C21.9948 17.3227 20.6523 15.9802 19.0023 15.9802ZM19.0023 20.1697C18.3421 20.1697 17.8053 19.6329 17.8053 18.9727C17.8053 18.3126 18.3421 17.7757 19.0023 17.7757C19.6624 17.7757 20.1993 18.3126 20.1993 18.9727C20.1993 19.6329 19.6624 20.1697 19.0023 20.1697Z' fill='white' />
            <path d='M23.8092 4.41424C23.6392 4.1967 23.3786 4.06981 23.1024 4.06981H5.54159L4.73361 0.68916C4.63695 0.285189 4.27573 4.09994e-09 3.86037 4.09994e-09H0.897755C0.401909 -4.68712e-05 0 0.401862 0 0.897708C0 1.39356 0.401909 1.79546 0.897755 1.79546H3.15175L6.06946 14.0038C6.16611 14.4081 6.52733 14.693 6.9427 14.693H20.9179C21.3305 14.693 21.6902 14.4117 21.7893 14.0113L23.9738 5.18328C24.0399 4.9152 23.9792 4.63179 23.8092 4.41424ZM20.2155 12.8975H7.65131L5.97069 5.86532H21.9553L20.2155 12.8975Z' fill='white' />
            <path d='M8.139 15.9802C6.4889 15.9802 5.14648 17.3227 5.14648 18.9727C5.14648 20.6228 6.48895 21.9653 8.139 21.9653C9.78906 21.9653 11.1315 20.6228 11.1315 18.9727C11.1315 17.3227 9.78906 15.9802 8.139 15.9802ZM8.139 20.1697C7.47886 20.1697 6.94199 19.6329 6.94199 18.9727C6.94199 18.3126 7.47886 17.7757 8.139 17.7757C8.79915 17.7757 9.33601 18.3126 9.33601 18.9727C9.33601 19.6329 8.79915 20.1697 8.139 20.1697Z' fill='white' />
        </svg>
    );
}

export default React.memo(CartIcon);
