import ACTIONS from '../constants/actionsTypes';

const initialState = {
    offerContent : '',
    error        : null,
    isLoading    : false
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_OFFER_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_OFFER_SUCCESS:
            return {
                ...state,
                offerContent : data,
                error        : null,
                isLoading    : false
            };
        case ACTIONS.GET_OFFER_ERROR:
            return {
                ...state,
                offerContent : '',
                error        : data,
                isLoading    : false
            };
        default:
            return state;
    }
};
