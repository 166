/* eslint-disable react/jsx-no-bind */
import React      from 'react';
import PropTypes  from 'prop-types';
import classNames from 'classnames';
import { Link }   from 'react-router-dom';
import style      from './Button.module.scss';

function Button({ name, link, children, event, clsTitle, cart, disabled, loading }) {
    return (
        link ?
            // eslint-disable-next-line react/jsx-no-bind,max-len
            <Link
                to={link}
                className={classNames(style.button, {
                    [clsTitle]       : clsTitle,
                    [style.disabled] : disabled || loading
                })}
                onClick={() => (event && !disabled && !loading) ? event() : null}
            >
                <>
                    {children}

                    {
                        name ? name : 'Button'
                    }
                </>
            </Link>

            :

            // eslint-disable-next-line react/jsx-no-bind
            <button
                data-obj={Object.keys(cart).length > 0 ? JSON.stringify(cart) : null}
                className={classNames(style.button, {
                    [clsTitle]       : clsTitle,
                    [style.disabled] : disabled || loading
                })}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={(e) => (event && !disabled && !loading) ? event(e) : null}>
                <>
                    {children}

                    <span>
                        {
                            name ? name : 'Button'
                        }
                    </span>
                </>
            </button>
    );
}

Button.propTypes = {
    name     : PropTypes.string,
    link     : PropTypes.string,
    children : PropTypes.node,
    event    : PropTypes.func,
    clsTitle : PropTypes.string,
    cart     : PropTypes.object,
    disabled : PropTypes.bool,
    loading  : PropTypes.bool
};

Button.defaultProps = {
    name     : null,
    link     : null,
    children : null,
    event    : null,
    clsTitle : '',
    cart     : {},
    disabled : false,
    loading  : false
};

export default React.memo(Button);
