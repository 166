import React, { useCallback }         from 'react';
import classNames    from 'classnames';
import { Link } from 'react-router-dom';
import Logo          from '../../assets/images/logo.png';
import MasterCard    from '../../assets/images/master.png';
import Visa          from '../../assets/images/visa.png';
import FbIcon        from '../../assets/icon/FbIcon';
import TwitIcon      from '../../assets/icon/TwitIcon';
// import useMediaQuery from '../../hooks/useMediaQuery';
import { MODALS }    from '../../constants';
import { useModal }  from '../../utils/useModal';
import style         from './footer.module.scss';

function Footer() {
    // const isTablet = useMediaQuery('(min-width: 768px)');
    const { toggleModal } = useModal();
    const toggleOfferPopup = useCallback(() => {
        toggleModal({ id: MODALS.POPUP_OFFER });
    }, []);

    return (
        <footer className={style.footer}>
            <div className={classNames(style['footer-wrapper'], style.container)}>
                <div className={style['logo-wrapper']}>
                    <Link
                        to='/'
                        style={{
                            height : 50
                    }}>
                        <img
                            className={'lazyload'} data-src={Logo}
                            alt={'logo'} />
                    </Link>

                    {/* {*/}
                    {/*    isTablet &&*/}
                    <>
                        <p>
                            Працюємо 3 8.00 до 20.00
                        </p>

                        <p>
                            Безкоштовна гаряча лінія
                        </p>

                        <a href='tel:0503771567'>  0 503 771 567 </a>
                        <a href='tel:0633771567'>  0 633 771 567 </a>
                        <a href='tel:0963771567'>  0 963 771 567 </a>

                        <div className={style.social}>
                            <a href='/'>
                                <FbIcon />
                            </a>

                            <a href='/'>
                                <TwitIcon />
                            </a>
                        </div>
                    </>
                    {/* }*/}
                </div>

                <nav>
                    <ul>
                        <li>
                            <h4>ПРО КОМПАНІЮ</h4>
                        </li>

                        <li>
                            <Link to='/about-us' >
                                Про нас
                            </Link>
                        </li>

                        <li>
                            <Link to={'/contact'}>
                                Контакти
                            </Link>
                        </li>

                        <li>
                            <Link to={'/vacancies'}>
                                Вакансії
                            </Link>
                        </li>
                    </ul>
                </nav>

                <nav>
                    <ul>
                        <li>
                            <h4>КЛІЄНТАМ</h4>
                        </li>

                        <li>
                            <button
                                className = {style.offerButton}
                                onClick={toggleOfferPopup}
                            >
                                Публічна оферта
                            </button>
                        </li>
                    </ul>
                </nav>

                <nav>
                    <ul>
                        <li>
                            <h4> Інформація</h4>
                        </li>

                        <li>
                            <Link to='/history'>
                                Наша історія
                            </Link>
                        </li>

                        <li>
                            <Link to='/delivery-and-payment' >
                                Оплата і доставка
                            </Link>
                        </li>

                        <li>
                            <a href='https://old.farmacia.ua/Akcioneram/' target='_blank' rel='noreferrer'>
                                Інформація для акціонерів та стейкхолдерів
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={style['footer-subscribe']}>
                <p>
                    Інформація на сайті має ознайомлювальний характер і не може бути заміною очної консультації лікаря.
                </p>

                <div className={style['img-wrapper']}>
                    <img
                        data-src={Visa}
                        className={'lazyload'}
                        alt={''} />
                    <img
                        data-src={MasterCard} className={'lazyload'}
                        alt={''} />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
