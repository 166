import ACTIONS from '../constants/actionsTypes';

const initialState = {
    list                  : [],
    analogList            : [],
    error                 : null,
    isLoading             : false,
    isLoadingAnalogues    : false,
    analoguesError        : false,
    searchList            : [],
    searchError           : null,
    isLoadingSearch       : false,
    searchString          : '',
    page                  : 1,
    pageSize              : 12,
    pages                 : 1,
    availabilityList      : [],
    isLoadingAvailability : false,
    availabilityError     : null
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                list      : data,
                isLoading : false
            };
        case ACTIONS.GET_PRODUCTS_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };
        case ACTIONS.CLEAR_ACCOUNTS_LIST:
            return {
                ...state,
                list : []
            };
        case ACTIONS.GET_SEARCH_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoadingSearch : true
            };
        case ACTIONS.GET_SEARCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                searchList      : data.searchList,
                searchString    : data.searchString,
                page            : +data.page,
                pages           : data.pages,
                isLoadingSearch : false
            };
        case ACTIONS.SEARCH_PRODUCTS_LIST_CHANGE_PAGE:
            return {
                ...state,
                searchList      : [ ...state.searchList, ...data.searchList ],
                page            : +data.page,
                isLoadingSearch : false
            };
        case ACTIONS.GET_SEARCH_PRODUCTS_ERROR:
            return {
                ...state,
                searchError     : data,
                isLoadingSearch : false
            };
        case ACTIONS.CLEAR_SEARCH_LIST:
            return {
                ...state,
                searchList      : [],
                searchError     : null,
                isLoadingSearch : false,
                searchString    : '',
                page            : 1,
                pages           : 1
            };
        case ACTIONS.GET_PRODUCTS_ANALOGS_REQUEST:
            return {
                ...state,
                isLoadingAnalogues : true
            };

        case ACTIONS.GET_PRODUCTS_ANALOGS_SUCCESS:
            return {
                ...state,
                analogList         : data,
                isLoadingAnalogues : false
            };
        case ACTIONS.GET_PRODUCTS_ANALOGS_ERROR:
            return {
                ...state,
                analoguesError     : data,
                isLoadingAnalogues : false
            };
        case ACTIONS.GET_PRODUCTS_AVAILABILITY_REQUEST:
            return {
                ...state,
                isLoadingAvailability : true
            };

        case ACTIONS.GET_PRODUCTS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availabilityList      : data || [],
                isLoadingAvailability : false
            };
        case ACTIONS.GET_PRODUCTS_AVAILABILITY_ERROR:
            return {
                ...state,
                availabilityError     : data,
                isLoadingAvailability : false
            };
        default:
            return state;
    }
};
