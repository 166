import api     from '../../apiSingleton';
import ACTIONS from '../constants/actionsTypes';

export function getFilterCatalog(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_FILTER_CATALOG_REQUEST
            });

            const filterCatalog = await api.FilterCatalog.list(params);
            const filters = {};

            Object.keys(filterCatalog).forEach(key => {
                filterCatalog[key].filters.forEach(filter => filters[filter.id] = filter);
            });

            dispatch({
                type : ACTIONS.GET_FILTER_CATALOG_SUCCESS,
                data : { filterCatalog,  filters }
            });
        } catch (err) {
            console.log('[ERROR] getFilterCatalog', err);
            dispatch({
                type : ACTIONS.GET_FILTER_CATALOG_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function getSearchFilterCatalog(filtersList) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_FILTER_CATALOG_REQUEST
            });

            const filters = {};

            Object.keys(filtersList).forEach(key => {
                filtersList[key].filters.forEach(filter => filters[filter.id] = filter);
            });

            dispatch({
                type : ACTIONS.GET_FILTER_CATALOG_SUCCESS,
                data : { filterCatalog: filtersList,  filters }
            });
        } catch (err) {
            console.log('[ERROR] getFilterCatalog', err);
            dispatch({
                type : ACTIONS.GET_FILTER_CATALOG_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
