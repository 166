import Base from './Base.js';

class Cities extends Base {
    list(queryParams = { page: 1 }) {
        return this.apiClient.get({
            requestURL : 'api/cities',
            queryParams
        });
    }
}

export default Cities;
