import React        from 'react';
import PropTypes    from 'prop-types';
import classNames   from 'classnames';
import SpinnerIcon  from '../../assets/icon/SpinnerIcon';
import style        from './Spinner.module.scss';

function Spinner({ className }) {
    return (
        <div className={classNames(style.spinner, className)}>
            <SpinnerIcon />
        </div>
    );
}

Spinner.propTypes = {
    className : PropTypes.string
};

Spinner.defaultProps = {
    className : ''
};

export default React.memo(Spinner);
