import products               from './Products';
import catalogTree            from './CatalogTree';
import catalog                from './Catalog';
import filterCatalog          from './FilterCatalog';
import catalogActiveCheckbox  from './CatalogActiveCheckbox';
import addInCart              from './AddInCart';
import heroBanner             from './HeroBanner';
import cart                   from './Cart';
import productRandom          from './ProductRandom';
import cities                 from './Cities';
import currentCity            from './CurrentCity';
import checkout               from './Checkout';
import VisuallyImpaired       from './VisuallyImpaired';
import order                  from './Order';
import offer                  from './Offer';
import showcases              from './Showcases';
import personalArea           from './PersonalArea';

export default {
    products,
    personalArea,
    VisuallyImpaired,
    catalogTree,
    catalog,
    filterCatalog,
    cities,
    heroBanner,
    catalogActiveCheckbox,
    cart,
    addInCart,
    productRandom,
    currentCity,
    checkout,
    order,
    offer,
    showcases
};
