/* eslint-disable more/no-duplicated-chains */
import ACTIONS from '../constants/actionsTypes';

const initialState = {
    list      : [],
    error     : null,
    isLoading : false,
    page      : 1,
    pageSize  : 12
};

export default (state = initialState, action = {}) => {
    const { type, data } = action;

    switch (type) {
        case ACTIONS.GET_CATALOG_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        case ACTIONS.GET_CATALOG_SUCCESS:
            return {
                ...state,
                list      : data,
                page      : +data.page,
                isLoading : false
            };
        case ACTIONS.GET_CATALOG_ERROR:
            return {
                ...state,
                error     : data,
                isLoading : false
            };
        case ACTIONS.CATALOG_CHANGE_PAGE:
            return {
                ...state,
                list : { ...data,
                    products : [
                        ...(state.list?.products ? [ ...state.list.products ] : []),
                        ...data.products
                    ]
                },
                page      : +data.page,
                isLoading : false
            };
        default:
            return state;
    }
};
