import ACTIONS from '../constants/actionsTypes';

export function getCurrentCity(params) {
    return dispatch => dispatch({
        type : ACTIONS.GET_CURRENT_CITY,
        data : params
    });
}

export function setDistrict(params) {
    return dispatch => dispatch({
        type : ACTIONS.SET_DISTRICT,
        data : params
    });
}
