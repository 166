import React, { useCallback } from 'react';
import online from '../../assets/images/online.png';
import Ellipse from '../../assets/icon/Ellipse';
import { MODALS } from '../../constants';
import { useModal } from '../../utils/useModal';
import style from './ExpertPharmacist.module.scss';


function ExpertPharmacist() {
    const { toggleModal } = useModal();

    const togglePopup = useCallback(({ target }) => {
        const modal = target.closest('button').getAttribute('data-id');

        if (modal) {
            toggleModal({ id: MODALS.POPUP_CONSULTATION });
        }
    }, []);

    return (
        <button
            className={style.wrapper}
            onClick={togglePopup}
            data-id='POPUP_CONSULTATION'
        >
            <div className={style.img}>
                <img   src={online} alt='test' />
                <Ellipse />
            </div>

            <h3>
                Отримати послугу експерта - фармацевта
            </h3>
        </button>
    );
}

export default ExpertPharmacist;
