import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import ArrowRight from '../../assets/icon/ArrowRight';
// import FacebookIcon from '../../assets/icon/FacebookIcon';
import GoogleIcon from '../../assets/icon/GoogleIcon';
import api from '../../apiSingleton';
import { MODALS }    from '../../constants';
import History from '../../history';
import Spinner from '../Spinner';
import { useModal } from '../../utils/useModal';
import ClosePopupIcon from '../../assets/icon/ClosePopupIcon';
import style from './PopupLogIn.module.scss';

function PopupLogIn() {
    const email = useRef();
    const password = useRef();
    const [ loading, setLoading ] = useState(false);
    const popupBody = useRef(null);
    const popup = useRef();
    const { dismiss, toggleModal } = useModal();
    const [ initPopup, setInitPopup ] = useState(false);
    const [ change, setChange ] = useState(false);
    const [ error, setError ] = useState('');

    const [ event, updateEvent ] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        { username: '', password: '' }
    );

    useEffect(() => {
        document.addEventListener('click', closeModal);
        setInitPopup(true);
        disableBodyScroll(document.body);

        return () => document.removeEventListener('click', closeModal);
    }, []);

    const openRegister = useCallback(() => {
        clearAllBodyScrollLocks();
        dismiss();
        toggleModal({ id: MODALS.POPUP_REGISTER });
    }, []);

    const closeModal = useCallback(({ target }) => {
        if (popupBody?.current && !popupBody?.current.contains(target)) {
            enableBodyScroll(document.body);
            clearAllBodyScrollLocks();
            dismiss();
        }

        if (target.closest('.register')) {
            clearAllBodyScrollLocks();
        }
    }, [ popupBody ]);


    const validateEmail = useCallback((eml: email) => {
        const re = /\S+@\S+\.\S+/;

        return re.test(eml);
    }, []);

    const closeBtn = useCallback(() => {
        dismiss();
        enableBodyScroll(document.body);
    }, [ popupBody ]);

    const requestForm = useCallback(async (e) => {
        e.preventDefault();

        if (!validateEmail(event.username) && !event.password.length < 5) {
            setChange(true);

            return;
        }

        if (change) setChange(false);

        setLoading(true);

        try {
            const request = await api.authenticate.createToken(event);

            const { token, id } = request;
            const checkRecipe = document.cookie.split('; ').find(row => row.startsWith('recipe='))?.split('=')[1];

            if (token) {
                if (error) setError('');
                api.apiClient.setToken(token, id);
                enableBodyScroll(document.body);

                if (checkRecipe) {
                    document.cookie = 'recipe=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    toggleModal({ id: MODALS.POPUP_DRAG });
                } else {
                    History.push('/personal-area');
                }
            }
            // eslint-disable-next-line no-catch-shadow,no-shadow
        } catch ({ errors }) {
            setError(errors.message);
            setLoading(false);
        }
    }, [ event ]);

    const changeHandle = useCallback(({ target })  => {
        setError('');
        setChange(false);
        updateEvent({ [target.name === 'email' ? 'username' : target.name]: target.value });
    }, []);

    return (
        <>
            <CSSTransition
                timeout={300}
                in={initPopup}
                unmountOnExit
                classNames='item'
                mode={'out-in'}
            >
                <section className={style['popup-login']}  ref={popup}>
                    <div className={style['popup-login__wrapper']} ref={popupBody}>
                        <button className={style['popup-login__close']} onClick={closeBtn}>
                            <ClosePopupIcon />
                        </button>

                        { loading &&  <Spinner /> }
                        <h2>Вхід в кабінет</h2>

                        <form onSubmit={requestForm}>
                            <label>
                                <input
                                    ref={email}
                                    name={'email'}
                                    type='text'
                                    placeholder={'Введіть Email'}
                                    onChange={changeHandle}
                                />
                                {
                                    (change && !validateEmail(event.username)) && <span>Enter correct email</span>
                                }
                            </label>
                            <label>
                                <input
                                    ref={password}
                                    name={'password'}
                                    type='password'
                                    placeholder={'Введіть пароль'}
                                    onChange={changeHandle}
                                />
                                {
                                    // eslint-disable-next-line max-len
                                    (change && event.password.length < 5) && <span>Password must be at least 5 characters</span>
                                }
                            </label>
                            <button className={classNames({ [style.error]: error?.length > 0 })}>
                                Увійти

                                <ArrowRight />
                            </button>
                        </form>

                        <div
                            style={{
                            position : 'relative'
                        }}>

                            { error?.length > 0 &&  <span
                                style={{
                                color    : 'red',
                                position : 'absolute'
                            }}
                                className={style.link}
                            >Невіриний логін або пароль</span> }

                            <Link
                                className={style.link}
                                to={'/'}>
                                Забули пароль?
                            </Link>
                        </div>

                        <div className={style.social}>
                            {/* <button>
                                <FacebookIcon />
                                Продовжити  з Facebook
                            </button> */}
                            <a href={'https://admin.farmacia.ua/connect/google'}>
                                <GoogleIcon />
                                Продовжити  з Google
                            </a>
                        </div>

                        <p>
                            Ще не зареєстровані?

                            <button className={'register'} onClick={openRegister}>
                                Реєстрація
                            </button>
                        </p>
                    </div>
                </section>
            </CSSTransition>
        </>
    );
}

export default React.memo(PopupLogIn);
