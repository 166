import api     from '../../apiSingleton';
import ACTIONS from '../constants/actionsTypes';

export function getCities(params, queryParams) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_CITIES_REQUEST
            });

            const cities = await api.cities.list(params, queryParams);

            dispatch({
                type : ACTIONS.GET_CITIES_SUCCESS,
                data : cities
            });
        } catch (err) {
            console.log('[ERROR] getcities', err);
            dispatch({
                type : ACTIONS.GET_CITIES_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
