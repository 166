import Base from './Base.js';

class HeroBanner extends Base {
    list(queryParams = 'banner') {
        return this.apiClient.get({
            requestURL : `api/banners/${queryParams}`
        });
    }
}

export default HeroBanner;
