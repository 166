import api     from '../../apiSingleton';
import ACTIONS from '../constants/actionsTypes';

export function getCatalogTree(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : ACTIONS.GET_CATALOG_TREE_REQUEST
            });

            const catalogTree = await api.catalogTree.list(params);

            dispatch({
                type : ACTIONS.GET_CATALOG_TREE_SUCCESS,
                data : catalogTree
            });
        } catch (err) {
            console.log('[ERROR] getCatalogTree', err);
            dispatch({
                type : ACTIONS.GET_CATALOG_TREE_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
