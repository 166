import React from 'react';

function FbIcon() {
    return (
        <svg
            style={{ minWidth: '0.438rem' }}
            width='7' height='12' viewBox='0 0 7 12'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4.52943 11.2608V6.19195H6.17765L6.42265 4.20733H4.52943V2.94321C4.52943 2.37052 4.68322 1.97842 5.47544 1.97842H6.47928V0.20902C5.99086 0.154714 5.49991 0.128492 5.00869 0.130476C3.55182 0.130476 2.55156 1.05321 2.55156 2.74716V4.20362H0.914062V6.18824H2.55513V11.2608H4.52943Z' fill='#A7A7A7' />
        </svg>
    );
}

export default React.memo(FbIcon);
