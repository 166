import React from 'react';

function CloseIcon() {
    return (
        <svg
            width='16' height='16' viewBox='0 0 16 16'
            fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L15 15' stroke='currentColor' />
            <path d='M15 1L1 15' stroke='currentColor' />
        </svg>


    );
}

export default React.memo(CloseIcon);
